import { Image, message, Steps } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserContext } from '../../data/userContext';
import axios from 'axios';
import './OnboardingPage.less';
import OnboardingFirstImage from '../../assets/onboarding-first.png';
import OnboardingSecondImage from '../../assets/onboarding-second.png';
import { ReactComponent as IconPlay } from '../../assets/onboarding_play.svg';
import { ReactComponent as IconMic } from '../../assets/onboarding_mic.svg';
import { ReactComponent as IconStreamer } from '../../assets/onboarding_streamer.svg';
import { ReactComponent as IconText } from '../../assets/onboarding_text.svg';
import { ReactComponent as IconManager } from '../../assets/onboarding_manager.svg';
import { ReactComponent as IconAI } from '../../assets/onboarding_ai.svg';
import { ReactComponent as IconGoogle } from '../../assets/onboarding_google.svg';
import { ReactComponent as IconTikTok } from '../../assets/onboarding_tiktok.svg';
import { ReactComponent as IconInstagram } from '../../assets/onboarding_instagram.svg';
import { ReactComponent as IconTwitter } from '../../assets/onboarding_twitter.svg';
import { ReactComponent as IconYoutube } from '../../assets/onboarding_youtube.svg';
import { ReactComponent as IconFacebook } from '../../assets/onboarding_facebook.svg';
import { ReactComponent as IconRightArrow } from '../../assets/onboarding_right_arrow.svg';
import { ReactComponent as IconLogoGray } from '../../assets/spikes_logo_gray.svg';
import { ReactComponent as IconLogoText } from '../../assets/spikes_logo_text.svg';
import { ReactComponent as IconFace } from '../../assets/face_person_new.svg';
import FacePerson from '../../assets/face_person.png';
import { ReactComponent as IconLogo } from '../../assets/logo_sidebar.svg';
import { ReactComponent as IconBg } from '../../assets/onboarding_bg.svg';

import { GiftOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { shuffleArrayWithOtherLast } from '../../utils';

const firstStepItems = [
  {
    value: 'business',
    label: 'Business',
    icon: <ShopOutlined />,
  },
  {
    value: 'content_creator',
    label: 'Content Creator',
    icon: <IconPlay />,
  },
  {
    value: 'podcast_producer',
    label: 'Podcast Producer',
    icon: <IconMic />,
  },
  {
    value: 'twitch_streamer',
    label: 'Twitch Streamer',
    icon: <IconStreamer />,
  },
  {
    value: 'video_editor',
    label: 'Video Editor',
    icon: <IconText />,
  },
  {
    value: 'ai_enthusiast',
    label: 'AI Enthusiast',
    icon: <IconAI />,
  },
  {
    value: 'social_media_manager',
    label: 'Social Media Manager',
    icon: <IconManager />,
  },
  {
    value: 'other',
    label: 'Other',
    icon: <UserOutlined />,
  },
];

const secondStepItems = [
  {
    value: 'referred_by_friend',
    label: 'Referred by a Friend',
    icon: <GiftOutlined />,
  },
  {
    value: 'google',
    label: 'Google',
    icon: <IconGoogle />,
  },
  {
    value: 'tiktok',
    label: 'TikTok',
    icon: <IconTikTok />,
  },
  {
    value: 'instagram',
    label: 'Instagram',
    icon: <IconInstagram />,
  },
  {
    value: 'twitter',
    label: 'Twitter',
    icon: <IconTwitter />,
  },
  {
    value: 'youTube',
    label: 'YouTube',
    icon: <IconYoutube />,
  },
  {
    value: 'facebook',
    label: 'Facebook',
    icon: <IconFacebook />,
  },
];

const onboardingList = [
  {
    id: 0,
    name: 'profession',
    random_order: true,
    title: 'Which best describes you?',
    options: [
      {
        value: 'Content Creator / Influencer',
        label: 'Content Creator / Influencer',
      },
      {
        value: 'Business Owner',
        label: 'Business Owner',
      },
      {
        value: 'Video Editing Professional / Freelancer',
        label: 'Video Editing Professional / Freelancer',
      },
      {
        value: 'Agency / Creative Studio',
        label: 'Agency / Creative Studio',
      },
      {
        value: 'Part of a creative team',
        label: 'Part of a creative team',
      },
      {
        value: 'Other',
        label: 'Other',
      },
    ],
  },
  {
    id: 10,
    name: 'usage_goal',
    title: "What's your main goal with Spikes?",
    options: [
      {
        value: 'Scale my content production',
        label: 'Scale my content production',
      },
      {
        value: 'Save time on video editing',
        label: 'Save time on video editing',
      },
      {
        value: 'Create more professional-looking content',
        label: 'Create more professional-looking content',
      },
      {
        value: 'Grow my social media presence',
        label: 'Grow my social media presence',
      },
      {
        value: 'Acquire Leads and sales',
        label: 'Acquire Leads and sales',
      },
      {
        value: 'Manage multiple clients/channels',
        label: 'Manage multiple clients/channels',
      },
      {
        value: 'Other',
        label: 'Something else',
      },
    ],
  },
  {
    id: 15,
    name: 'source_of_discovery',
    title: "How did you hear about Spikes?",
    options: [
      {
        value: 'Referred by a friend',
        label: 'Referred by a friend',
      },
      {
        value: 'Google Search',
        label: 'Google Search',
      },
      {
        value: 'TikTok Ad',
        label: 'TikTok Ad',
      },
      {
        value: 'Instagram Ad',
        label: 'Instagram Ad',
      },
      {
        value: 'Twitter',
        label: 'Twitter',
      },
      {
        value: 'YouTube',
        label: 'YouTube',
      },
      {
        value: 'Facebook Ad',
        label: 'Facebook Ad',
      },
      {
        value: 'Other',
        label: 'Other',
      },
    ],
  },
  {
    id: 20,
    name: 'industry',
    title: "What industry are you part of?",
    options: [
      {
        value: 'Marketing & Advertising',
        label: 'Marketing & Advertising',
      },
      {
        value: 'Technology & Software',
        label: 'Technology & Software',
      },
      {
        value: 'Media & Entertainment',
        label: 'Media & Entertainment',
      },
      {
        value: 'Retail & E-commerce',
        label: 'Retail & E-commerce',
      },
      {
        value: 'Freelance & Solopreneurs',
        label: 'Freelance & Solopreneurs',
      },
      {
        value: 'Other',
        label: 'Other',
      },
    ],
  },
  // {
  //   id: 20,
  //   name: 'source_of_discovery',
  //   random_order: true,
  //   title: 'How did you hear about Spikes?',
  //   options: [
  //     {
  //       value: 'Referred by a friend',
  //       label: 'Referred by a friend',
  //     },
  //     {
  //       value: 'Google Search',
  //       label: 'Google Search',
  //     },
  //     {
  //       value: 'TikTok Ad',
  //       label: 'TikTok Ad',
  //     },
  //     {
  //       value: "Instagram Ad",
  //       label: "Instagram Ad",
  //     },
  //     {
  //       value: "Twitter",
  //       label: "Twitter",
  //     },
  //     {
  //       value: "YouTube",
  //       label: "YouTube",
  //     },
  //     {
  //       value: "Facebook Ad",
  //       label: "Facebook Ad",
  //     },
  //   ],
  // },
  // {
  //   id: 30,
  //   name: 'clips_share_platform',
  //   random_order: true,
  //   title: 'Where do you primarily share your videos?',
  //   options: [
  //     {
  //       value: 'Instagram',
  //       label: 'Instagram',
  //     },
  //     {
  //       value: 'TikTok',
  //       label: 'TikTok',
  //     },
  //     {
  //       value: 'YouTube Shorts',
  //       label: 'YouTube Shorts',
  //     },
  //     {
  //       value: 'LinkedIn',
  //       label: 'LinkedIn',
  //     },
  //     {
  //       value: 'Facebook',
  //       label: 'Facebook',
  //     },
  //     {
  //       value: 'YouTube',
  //       label: 'YouTube',
  //     },
  //     {
  //       value: 'Other',
  //       label: 'Other',
  //     },
  //   ],
  // },
  // {
  //   id: 40,
  //   name: 'prev_editing_challenge',
  //   random_order: true,
  //   title: 'What’s your biggest challenge with video editing right now?',
  //   options: [
  //     {
  //       value: 'Finding time to edit consistently',
  //       label: 'Finding time to edit consistently',
  //     },
  //     {
  //       value: 'Adapting content to fit each platform’s format',
  //       label: 'Adapting content to fit each platform’s format',
  //     },
  //     {
  //       value: 'Making videos engaging with effects, captions, and music',
  //       label: 'Making videos engaging with effects, captions, and music',
  //     },
  //     {
  //       value: 'Repurposing existing content efficiently',
  //       label: 'Repurposing existing content efficiently',
  //     },
  //     {
  //       value: 'Other',
  //       label: 'Other',
  //     },
  //   ],
  // },
  // {
  //   id: 50,
  //   name: 'prev_editing_method',
  //   random_order: true,
  //   title: 'How do you currently create and edit short videos?',
  //   options: [
  //     {
  //       value: 'An app on my phone',
  //       label: 'An app on my phone',
  //     },
  //     {
  //       value: 'Desktop app',
  //       label: 'Desktop app',
  //     },
  //     {
  //       value: 'Editing website',
  //       label: 'Editing website',
  //     },
  //     {
  //       value: 'Outsource with a team',
  //       label: 'Outsource with a team',
  //     },
  //     {
  //       value: 'I don’t',
  //       label: 'I don’t',
  //     },
  //     {
  //       value: 'Other',
  //       label: 'Other',
  //     },
  //   ],
  // },
];

const initialAnswers = {
  profession: '',
  organization_size: '',
  prev_editing_method: '',
  prev_editing_challenge: '',
  videos_type: '',
  usage_goal: '',
  source_of_discovery: '',
  industry: ''
};

const OnboardingPage = () => {
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [answers, setAnswers] = useState<any>(initialAnswers);
  const [messageApi, contextHolder] = message.useMessage();
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const currentList = onboardingList[onboardingStep];
  const currentAnswer = answers[currentList?.name];
  const currentOptions = useMemo(
    () =>
      currentList?.random_order
        ? shuffleArrayWithOtherLast(currentList?.options)
        : currentList?.options,
    [onboardingStep],
  );

  useEffect(() => {
    if (
      userContext?.user?.audience ||
      userContext?.user?.referrer_source ||
      userContext?.user?.category ||
      userContext?.user?.profession ||
      userContext?.user?.organization_size ||
      userContext?.user?.prev_editing_method
    ) {
      navigate('/spikes/videos');
    }
  }, [userContext]);

  useEffect(() => {
    const items = document.querySelectorAll('.onboarding__item');
    items.forEach((item, index) => {
      setTimeout(() => {
        item.classList.add('fade-in');
      }, index * 100); // Adjust the delay to control the stagger effect
    });
  }, [onboardingStep]);

  // const handleClickNext = () => {
  //   if (!currentAnswer) {
  //     messageApi.info('Please select one option.');
  //     return;
  //   }
  //   if (onboardingStep < onboardingList.length - 1) {
  //     setOnboardingStep((prev) => prev + 1);
  //   } else {
  //     handleFinishOnboarding();
  //   }
  // };

  const handleClickBack = () => {
    if (onboardingStep > 0) {
      setOnboardingStep((prev) => prev - 1);
    }
  };

  const handleFinishOnboarding = async (data: any) => {
    const response = axios
      .put('/streamer/questioneer', data)
      .catch((error) => {
        if (error.response) {
          console.error(error.response);
        }
      });

    navigate('/spikes/videos');
    localStorage.setItem('onboarding_finished', 'true');
  };

  const handleItemClick = (value: string) => {
    const copy: any = { ...answers };
    copy[currentList.name] = value;

    setAnswers(copy);

    // Add a delay before moving to the next step
    setTimeout(() => {
      if (onboardingStep < onboardingList.length - 1) {
        setOnboardingStep((prev) => prev + 1);
      } else {
        handleFinishOnboarding(copy);
      }
    }, 300);
  };

  const isSelectedItem = (value: string) => {
    return currentAnswer === value;
  };

  return (
    <div className="onboarding">
      <IconLogo className="onboarding__main-logo" />
      {contextHolder}
      <div className="onboarding__wrapper">
        <IconBg className="onboarding__wrapper-bg" />
        <div className="onboarding__hero">
          <Image src={FacePerson} width={100} preview={false} />

          <div className="onboarding__hero-text">
            <div className="onboarding__hero-title">Welcome to Spikes!</div>
            <div className="onboarding__hero-subtitle">
              Help us get to know you better.
            </div>
          </div>
        </div>
        <div className="onboarding__content">
          <div className="onboarding__form-container">
            <div className="onboarding__form">
              <div className="onboarding__progress-bar">
                {onboardingList.map((section, index) => (
                  <div
                    key={section.name}
                    className={`onboarding__progress-item ${onboardingStep >= index && 'completed'
                      }`}
                  />
                ))}
              </div>
              <div className="onboarding__subtitle">
                #AI Video Repurpose Tool
              </div>
              <p className="onboarding__title">{currentList?.title}</p>
              <div className="onboarding__questions">
                {currentOptions.map((item) => (
                  <div
                    key={item.value + currentList?.name}
                    className={`${isSelectedItem(item.value) && 'onboarding-item-active'
                      }`}
                  >
                    <div
                      onClick={() => handleItemClick(item.value)}
                      className={`onboarding__item`}
                    >
                      {/* {item?.icon && item?.icon} */}
                      <p>{item.label}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="onboarding__buttons">
                {onboardingStep > 0 && (
                  <div
                    onClick={handleClickBack}
                    className="onboarding__back-button onboarding__btn"
                  >
                    <p>Back</p>
                  </div>
                )}
                {/* <div
                  onClick={handleClickNext}
                  className={`onboarding__next-button onboarding__btn ${!currentAnswer && 'disabled'
                    }`}
                >
                  <p>Next</p>
                  <IconRightArrow />
                </div> */}
              </div>
            </div>
          </div>
          {/* <div onClick={handleSkipNow} className="onboarding__skip">
          <div className="onboarding__skip-button">Skip Now</div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default OnboardingPage;
