import { Button, Layout, message, Input, Form } from 'antd';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { HeaderResponsive } from '../../components/header/header-responsive';
import { useContext, useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../themes/colors';
import { UserContext } from '../../data/userContext';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { useGoogleLogin } from '@react-oauth/google';
import { ReactComponent as IconTwitch } from '../../assets/twitch.svg';
import { ReactComponent as IconFacebook } from '../../assets/facebook_login_icon.svg';
import { ReactComponent as IconGoogle } from '../../assets/google.svg';
import handleTwitchLogin from '../../utils/twitchAuth';
import handleFacebookAuth from '../../utils/facebookAuth';
import { GenericModal } from '../../components/generic-modal';
import { postAudit } from '../../services/audit.service';
import LoginImage from '../../assets/login-hero.png';

import './SignUpPage.less';
import { getDifferenceInHours } from '../../utils';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const SignUpPage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();
  const hideLoginModals = localStorage.getItem('hideLoginModals');
  const [searchParams, setSearchParams] = useSearchParams();

  const decodedSearchParams = new URLSearchParams(decodeURIComponent(window.location.search));

  // Extracting query parameters
  const campaignId = decodedSearchParams.get('utm_id');
  const utm_campaign = decodedSearchParams.get('utm_campaign');
  const utm_source = decodedSearchParams.get('utm_source');
  const refCode = decodedSearchParams.get('ref');
  const fromSearchParam = decodedSearchParams.get('from');
  const googleSearchParam = decodedSearchParams.get('google');
  const supervisorToken = decodedSearchParams.get('supervisor_token');

  const campaignUrl = localStorage.getItem('campaign_url');

  const supervisorModeEnabled = supervisorToken && supervisorToken === process.env.REACT_APP_SUPERVISOR_TOKEN;

  const [form] = Form.useForm();
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('utm_source')) {
      localStorage.setItem('utm_source', window.location.search.slice(1));
    } else {
      localStorage.setItem('utm_source', 'none');
    }

    if (refCode) {
      localStorage.setItem('ref', refCode);
      searchParams.delete('ref');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (userContext?.user) {
      navigate('/spikes/videos');
    }
  }, [userContext]);

  useEffect(() => {
    if (fromSearchParam && fromSearchParam === 'api-home') {
      localStorage.setItem('login-from', fromSearchParam);
      searchParams.delete('from');
      setSearchParams(searchParams);
      if (userContext?.user) {
        navigate('/spikes/videos');
      }
    }
  }, [fromSearchParam]);

  useEffect(() => {
    if (googleSearchParam === 'true') {
      searchParams.delete('google');
      setSearchParams(searchParams);
      setTimeout(() => {
        handleGoogleLogin && handleGoogleLogin();
      }, 100);
    }
  }, []);


  const handleGoogleError = (error: any) => {
    messageApi.error('Google login error', 3);
    console.error('Google login error:', error);
    postAudit({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      user_action: `login error - google - ${JSON.stringify(error)}`,
      user_id: userContext?.user?.id
    });
  };

  const handleSuccessGoogleLogin = async (tokenResponse: any) => {
    if (tokenResponse) {
      if (tokenResponse.code) {
        const payload: any = {
          code: tokenResponse.code,
          referral_key: localStorage.getItem('ref') || '',
          is_mobile_login: isMobile,
          type: 'google',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        if (campaignUrl) payload.register_referral = campaignUrl;

        const response = await axios
          .post('/auth/social/twitch/login-or-register', payload)
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
            }
          });

        if (response?.status === 200) {
          localStorage.removeItem('ref');
          localStorage.removeItem('campaign_url');
          localStorage.removeItem('login_method');
          userContext?.setUser(response.data.user);

          if (!hideLoginModals) navigate('/spikes/welcome');
          else navigate('/spikes/videos');
        } else {
          console.error('No code provided by Google login', tokenResponse);
        }
      }
    }
  };


  const handleGoogleLoginButton = () => {
    localStorage.setItem('login_method', 'google');
    handleLoginWithoutTokenRequest();
  };

  const handleGoogleLogin = userContext?.user ? null : useGoogleLogin({
    onSuccess: handleSuccessGoogleLogin,
    flow: 'auth-code',
    scope: 'openid email profile',
    redirect_uri: process.env.REACT_APP_GOOGLE_RETURN_URL,
    ux_mode: 'redirect',
    onError: handleGoogleError,
    onNonOAuthError: handleGoogleError,
  });

  const handleTwitchButton = async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'sign_up',
      method: 'Twitch',
      event_name: 'fireEvent',
    });
    localStorage.setItem('login_method', 'twitch');

    handleLoginWithoutTokenRequest(true);
  };

  const handleFacebookButton = async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'sign_up',
      method: 'Facebook',
      event_name: 'fireEvent',
    });
    localStorage.setItem('login_method', 'facebook');

    handleFacebookLogin(true);
  };





  const handleFacebookLogin = async (isFacebook?: boolean) => {
    try {
      const response = await axios.get('streamer/profile');
      if (response?.status === 200) {
        try {
          if (response.data && response.data.username) {
            userContext?.setUser(response.data);
          } else {
            handleTokeFacebookLogin(isFacebook);
          }
        } catch (error) {
          window.location.reload();
        }
      } else {
        handleTokeFacebookLogin(isFacebook);
      }
    } catch (error: any) {
      handleTokeFacebookLogin(isFacebook);

      if (error.response) {
        console.log('streamer/profile error:', error.response);
      }
    }
  };
  const handleLoginWithoutTokenRequest = async (isTwitch?: boolean) => {
    try {
      const response = await axios.get('streamer/profile');
      if (response?.status === 200) {
        try {
          if (response.data && response.data.username) {
            userContext?.setUser(response.data);
          } else {
            handleTokenLogin(isTwitch);
          }
        } catch (error) {
          window.location.reload();
        }
      } else {
        handleTokenLogin(isTwitch);
      }
    } catch (error: any) {
      handleTokenLogin(isTwitch);

      if (error.response) {
        console.log('streamer/profile error:', error.response);
      }
    }
  };
  /* 
    This is being triggered only when the Token is expired meaning
    that they won't see the google/twitch login pages if token
    is still valid (e.g. if 'streamer/profile' doesn't throw an error)
  */

  const handleTokeFacebookLogin = async (isFacebook?: boolean) => {
    if (campaignId || utm_campaign || utm_source) localStorage.setItem('campaign_url', window.location.href);

    await handleFacebookAuth();

  };

  const handleTokenLogin = async (isTwitch?: boolean) => {
    if (campaignId || utm_campaign || utm_source) localStorage.setItem('campaign_url', window.location.href);
    if (isTwitch) {
      await handleTwitchLogin();
    } else {
      handleGoogleLogin && handleGoogleLogin();
    }
  };

  const handleEmailSubmit = async () => {
    setIsCodeSent(true);
    const response = await axios.post('/auth/request-email', { email: email }).catch((error) => {
      if (error.response) {
        messageApi.error('An unexpected error occurred. Email not sent.');
        console.error(error?.response?.data);
      }
    });

    if (response) {
      messageApi.success('Verification email sent. Please check your inbox.');
    }
  };



  const shouldHideGoogleButton =
    utm_source === 'META' ||
    utm_source === 'Facebook' ||
    utm_source === 'Facebbok' ||
    utm_source === 'TikTok';

  return (
    <div className='login-page'>
      {contextHolder}
      <div className="login-page___image">
        <img src={LoginImage} alt='Login Image' />
      </div>
      <div className="login-page___content">
        <p className="login-page___subtitle">#AI Video Repurpose Tool</p>
        <p className="login-page___title">Get Your Clips Now</p>
        <p className="login-page___description">Try it for FREE. No credit card required </p>


        <div className="login-page___buttons">
          {!shouldHideGoogleButton &&
            <>
              <Button
                id='login-with-google-button'
                onClick={handleGoogleLoginButton}
                style={{ background: '#fff', color: '#000' }}
                className="button"
              >
                <IconGoogle width={28} height={28} style={{ marginRight: 12, display: 'flex' }} />
                Sign up with Google
              </Button>
              <Button
                id='login-with-twitch-button'
                onClick={handleTwitchButton}
                style={{ background: '#fff', color: '#000' }}
                className="button"
              >
                <IconTwitch width={28} height={28} style={{ color: 'black', marginRight: 12 }} />
                Sign up with Twitch
              </Button>
            </>
          }
          <Button
            id='login-with-facebook-button'
            onClick={handleFacebookButton}
            style={{ background: '#fff', color: '#000' }}
            className="button"
          >
            <IconFacebook width={32} height={32} style={{ color: 'black', marginRight: 8 }} />
            Sign up with Facebook
          </Button>

          <p className="login-page___separator">or continue with email</p>
          <Form onFinish={handleEmailSubmit} form={form} layout="vertical">
            {!isCodeSent ? (
              <div>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: 'Please enter your email' },
                    { type: 'email', message: 'Please enter a valid email address' },
                  ]}
                >
                  <Input
                    className="login-page___email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
                <Button
                  id="login-with-email-button"
                  htmlType="submit"  // Use 'submit' to trigger form submission and validation
                  style={{ background: '#fff', color: '#000' }}
                  className="login-page___email-buttons"
                >
                  Continue With Email
                </Button>
                <p
                  className="login-page___forgot-password"
                  onClick={() => navigate('/')}
                >
                  Already have an account? Log in!
                </p>
              </div>
            ) : (
              <div>
                <Input
                  className="login-page___email"
                  placeholder="Email Address"
                  value={email}
                  disabled
                />
                <p className="login-page___confirmation">
                  Verification email sent. It can take up to 2 minutes.
                </p>
              </div>
            )}
          </Form>
          {shouldHideGoogleButton && <span style={{ marginTop: 3, textAlign: 'center', color: '#6F6F6F', fontSize: '12px', lineHeight: '14px' }}>To connect with Google or Twitch, please use your phone&apos;s browser</span>}



        </div>

      </div>
    </div>
  )

};

export default SignUpPage;
