import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { clamp, debounce } from 'lodash';
import { Image, message, Slider } from 'antd';
import { default as _ReactPlayer } from 'react-player';
import { ReactPlayerProps } from 'react-player/types/lib';
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;
import { default as _ReactPlayerClass } from 'react-player/lazy';
import { COLORS } from '../../themes/colors';
import { formatSeconds } from '../../utils/generic';

import IconPlay from '../../assets/icon-player-play.svg';
import { ReactComponent as IconPlayerPlay } from '../../assets/icon-clip-player-play.svg';
import IconPause from '../../assets/icon-player-pause.svg';
import IconVolume from '../../assets/icon-volume.svg';
import IconVolumeMuted from '../../assets/icon-volume-muted.svg';
import IconVolumeHigh from '../../assets/icon-volume-high.svg';
import { usePlayer } from './usePlayer';
import './Player.less';
import { useTraceUpdate } from '../../utils';
import { VideoTimeline } from './VideoTimeline';
import { Loading3QuartersOutlined, LoadingOutlined } from '@ant-design/icons';

export const MAX_TRIMMING_RANGE = 300;

export interface IPlayerProps {
  url: string;
  thumbnailUrl?: string;
  light?: boolean;
  startTime?: number;
  endTime?: number;
  height?: string | number;
  width?: string | number;
  showCrop?: boolean;
  disableTrim?: boolean;
  isVisible?: boolean;
  initialTrimValues?: [number, number];
  playingVideoScope?: [number, number];
  onPlay?: () => void;
  onPause?: () => void;
  onReady?: () => void;
  onProgress?: (progress: number) => void;
  onTrimChange?: (values: [number, number]) => void;
  trimValuesProp?: [number, number];
  enableTimeLine?: boolean;
  featureSliders?: any[];
  setFeatureSliders?: any;
  handleDeleteFeatureSlider?: (id: any) => void;
  setPlayerProgress?: (progress: number) => void;
  handleFeatureSliderTimeChange?: (id: any, time: number | number[]) => void;
  setCurrentSeekedTimeOfMainPlayer?: (time: number) => void;
  handleSplitVideo?: (splitTime: number) => void;
  feedScenesBreakpoints?: number[];
  handleDeleteFeedScene?: (startTime: number) => void;
  isProjectExpired?: boolean;
}
export const Player = (props: IPlayerProps, ref: any) => {
  const {
    url,
    thumbnailUrl,
    light = false,
    startTime = 0,
    endTime = 0,
    height = 280,
    showCrop = false,
    disableTrim = false,
    isVisible = true,
    initialTrimValues = showCrop ? [30, 42] : [0, 0],
    playingVideoScope,
    onPlay,
    onPause,
    onReady,
    onProgress,
    onTrimChange,
    trimValuesProp,
    enableTimeLine = false,
    featureSliders,
    setFeatureSliders,
    handleDeleteFeatureSlider,
    setPlayerProgress,
    handleFeatureSliderTimeChange,
    setCurrentSeekedTimeOfMainPlayer,
    handleSplitVideo,
    width = 'auto',
    feedScenesBreakpoints,
    handleDeleteFeedScene,
    isProjectExpired
  } = props;

  const {
    playerRef,
    ready,
    setIsReady,
    volume,
    setVolume,
    muted,
    setMuted,
    playing,
    setPlaying,
    trimValues,
    setTrimValues,
    duration,
    setDuration,
    progress,
    setProgress,
    loadedSeconds,
    setLoadedSeconds,
    styles,
  } = usePlayer({
    url,
    startTime,
    endTime,
    initialTrimValues,
    showCrop,
    light,
    onPause,
    onTrimChange,
  });

  const [showThumbnail, setShowThumbnail] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setPlayerProgress && setPlayerProgress(progress);
  }, [progress]);


  const [playedInTrimmingScope, setPlayedInTrimmingScope] = useState<boolean>(false);

  const isNumber = (value: any) => {
    return typeof value === 'number' && !Number.isNaN(value);
  };

  const startPlayingByTrimmingValue = () => {
    if (playingVideoScope) {
      if (isNumber(playingVideoScope[0])) {
        seekTo(playingVideoScope[0]);
      }
    }
  };

  const finishPlayingByTrimmingValue = (playedSeconds: number) => {
    if (playingVideoScope && !playedInTrimmingScope) {
      if (isNumber(playingVideoScope[1])) {
        if (playedSeconds >= playingVideoScope[1]) {
          onPause && onPause();
          setPlaying(false);
          setPlayedInTrimmingScope(true);
        }
      }
    }
  };

  const onDuration = (duration: number) => {
    seekTo(showCrop ? (trimValuesProp && trimValuesProp[0] || trimValues[0]) : 0);
    setDuration(Math.floor(duration));
    !showCrop && setTrimValues([0, duration]);
  };

  const onProgressNative = (state: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {
    blockPlayingBeforeTrimmingValue(state.playedSeconds);
    finishPlayingByTrimmingValue(state.playedSeconds);
    if (showCrop && state.playedSeconds === 0) {
      setProgress(trimValues[0]);
      onProgress && onProgress(trimValues[0]);
    } else {
      setProgress(
        parseFloat((Math.round(state.playedSeconds * 4) / 4).toFixed(2)),
      );
      onProgress && onProgress(Math.ceil(state.playedSeconds));
    }

    setLoadedSeconds(Math.ceil(state.loadedSeconds));
    const stopTime = showCrop ? (trimValuesProp && trimValuesProp[1] || trimValues[1]) : endTime || duration;
    if (stopTime && Math.floor(state.playedSeconds) >= stopTime) {
      setPlaying(false);
      onPause && onPause();
      setProgress(showCrop ? (trimValuesProp && trimValuesProp[0] || trimValues[0]) : 0);
      seekTo(showCrop ? (trimValuesProp && trimValuesProp[0] || trimValues[0]) : 0);
      onProgress && onProgress(0);
    }
  };

  const blockPlayingBeforeTrimmingValue = (playedSeconds: number) => {
    if (!trimValues) return;
    if (playedSeconds < (trimValuesProp && trimValuesProp[0] || trimValues[0])) {
      seekTo((trimValuesProp && trimValuesProp[0] || trimValues[0]));
    }
  };

  const debouncedOnSeek = useCallback(
    debounce((nextValue) => {
      onSeek(nextValue);
    }, 300),
    [trimValues, trimValuesProp],
  );

  const seekTo = (value: number) => {
    if (playerRef.current) {
      (playerRef.current as _ReactPlayerClass).seekTo(value, 'seconds');
      setCurrentSeekedTimeOfMainPlayer && setCurrentSeekedTimeOfMainPlayer(value);
    }
  };

  const onPlayPause = (play: boolean) => {
    if (playerRef.current && !play && endTime && progress >= endTime) {
      (playerRef.current as _ReactPlayerClass).seekTo(startTime, 'seconds');
    }

    setPlaying(!play);

    if (play) {
      onPause && onPause();
    } else {
      onPlay && onPlay();
    }
  };

  const onSeek = (value: number) => {
    if (showCrop) {
      if (value <= (trimValuesProp && trimValuesProp[0] || trimValues[0])) {
        seekTo((trimValuesProp && trimValuesProp[0] || trimValues[0]));
        return;
      } else if (value >= (trimValuesProp && trimValuesProp[1] || trimValues[1])) {
        seekTo((trimValuesProp && trimValuesProp[1] || trimValues[1]));
        return;
      }
    }
    seekTo(value);
  };

  const handleTrimmingBarChange = (values: [number, number]) => {
    if (values[0] + MAX_TRIMMING_RANGE <= values[1]) {
      seekTo(values[0]);
      setTrimValues([values[0], values[0] + MAX_TRIMMING_RANGE]);
    } else {
      // If only END trimming point is changed, seek to the end trimm of the video
      if (trimValues[1] !== values[1] && trimValues[0] === values[0]) {
        seekTo(values[1]);
      } else {
        seekTo(values[0]);
      }
      setTrimValues(values);
    }
  };

  const handleClickOnThumbnail = () => {
    if (isProjectExpired) {
      messageApi.error('This project is expired.', 5);
      return;
    }
    setShowThumbnail(false);
    onPlayPause(false);
  };

  return (
    <div className="player-wrapper player-clip cursor-pointer" ref={ref}>
      {contextHolder}
      <ReactPlayer
        ref={playerRef}
        url={url}
        controls={false}
        // light={light && thumbnailUrl}
        playing={isVisible && playing}
        volume={muted ? 0 : volume}
        height={height}
        width={'100%'}
        style={{ display: 'flex', justifyContent: 'center' }}
        pip={true}
        onStart={startPlayingByTrimmingValue}
        onDuration={onDuration}
        on
        onProgress={onProgressNative}
        playIcon={<IconPlayerPlay style={{ zIndex: 100 }} />}
        onReady={() => {
          if (!ready) {
            setPlaying(light);
            setIsReady(true);
            // onPlay && onPlay();
            onReady && onReady();
          }
        }}
      />
      <div style={styles.spikeVideoOverlay}></div>
      {thumbnailUrl && showThumbnail &&
        <div onClick={handleClickOnThumbnail} className="player-clip__thumbnail">
          <img src={thumbnailUrl} alt="Thumbnail" />
          <IconPlayerPlay />
          <div className='low-res-tag'>Low-Res Preview</div>
        </div>
      }
      {!showThumbnail && !ready &&
        <div onClick={handleClickOnThumbnail} className="player-clip__thumbnail">
          <img src={thumbnailUrl} alt="Thumbnail" />
          <Loading3QuartersOutlined spin style={{ fontSize: 32 }} />
        </div>
      }
      {ready && (
        <div style={styles.controls}>
          <div
            className="cursor-pointer"
            style={{ ...styles.button, visibility: enableTimeLine ? 'hidden' : 'visible' }}
            onClick={() => onPlayPause(playing)}
          >
            <Image style={{ height: playing ? 20 : 15 }} src={playing ? IconPause : IconPlay} preview={false} />
          </div>
          <div style={{ ...styles.progress, visibility: enableTimeLine ? 'hidden' : 'visible' }}>
            <Slider
              defaultValue={0}
              value={progress}
              min={0}
              step={0.25}
              max={duration}
              onChange={(value) => {
                setProgress(
                  parseFloat(
                    (
                      Math.round(
                        clamp(value, (trimValuesProp && trimValuesProp[0] || trimValues[0]), (trimValuesProp && trimValuesProp[1] || trimValues[1])) * 4,
                      ) / 4
                    ).toFixed(2),
                  ),
                );
                debouncedOnSeek(value);
              }}
              tooltip={{ open: false }}
              style={{ height: 8 }}
              trackStyle={styles.progressTrackStyle}
              handleStyle={styles.progressHandleStyle}
            />
            <div style={styles.loadedIndicatorContainer}>
              <div style={styles.loadedIndicator}></div>
            </div>
            <div style={styles.progressIndicator} className="handle">
              <span style={styles.progressIndicatorTime}>
                {formatSeconds(progress)}
              </span>
            </div>
            {showCrop && !enableTimeLine && (
              <>
                <div style={{ position: 'relative' }}>
                  {/* <div
                    style={{
                      width: 5,
                      height: 15,
                      backgroundColor: '#67FFCC',
                      top: 68.5,
                      position: 'absolute',
                      borderRadius: 8,
                    }}
                  ></div> */}
                  <div className="player-timeline">{formatSeconds(trimValues[0])} / {formatSeconds(trimValues[1])}</div>
                  {/* <div className="player-timeline">{formatSeconds(progress)} / {formatSeconds(duration)}</div> */}
                  <div style={styles.trim_progress}>
                    <Slider
                      defaultValue={0}
                      value={progress}
                      min={0}
                      step={0.25}
                      max={duration}
                      onChange={(value) => {
                        // setProgress(parseFloat((Math.round(clamp(value, trimValues[0], trimValues[1]) * 4) / 4).toFixed(2)));
                        // debouncedOnSeek(value);
                      }}
                      tooltip={{ open: false }}
                      style={{ height: 8, marginTop: 50, zIndex: -1 }}
                      trackStyle={styles.trim_progressTrackStyle}
                      handleStyle={styles.trim_progressHandleStyle}
                    />
                    <div style={styles.trim_loadedIndicatorContainer}>
                      {/* <div style={styles.trim_loadedIndicator}></div> */}
                    </div>
                    {/* <div style={styles.trim_progressIndicator} className='handle'>
                      <span style={styles.trim_progressIndicatorTime}>{formatSeconds(progress)}</span>
                    </div> */}
                    <div className="player-bottom-slider">
                      {/* @ts-expect-error style */}
                      <Slider
                        range={{ draggableTrack: true }}
                        value={trimValuesProp || trimValues}
                        step={0.25}
                        disabled={disableTrim}
                        min={0}
                        tooltip={{
                          formatter: (value) =>
                            value && formatSeconds(value, true),
                        }}
                        max={duration}
                        style={{ height: 8, marginTop: -10, zIndex: 1 }}
                        className="trimmer"
                        trackStyle={{
                          height: 10,
                          background: COLORS.PRIMARY,
                          opacity: 0.3,
                          zIndex: 20,
                          bottom: -2
                        }}
                        handleStyle={{
                          height: 28,
                          width: 28,
                          top: -4,
                          border: 0,
                          background: COLORS.PRIMARY,
                          borderRadius: 24,
                          zIndex: 30,
                        }}
                        onChange={handleTrimmingBarChange}
                      />
                    </div>
                  </div>
                  {/* <div
                    style={{
                      width: 5,
                      height: 15,
                      backgroundColor: '#67FFCC',
                      top: 68.5,
                      right: 1,
                      position: 'absolute',
                      borderRadius: 8,
                    }}
                  ></div> */}
                </div>
              </>
            )}
          </div>
          <div style={styles.controls}>
            <div
              className="cursor-pointer volume-icon"
              style={{ marginTop: 120 }}
            >
              <div onClick={() => setMuted(!muted || volume === 0)}>
                {volume >= 0.8 && !muted && (
                  <Image src={IconVolumeHigh} width={22} height={22} preview={false} />
                )}
                {volume < 0.8 && !muted && (
                  <Image src={IconVolume} width={22} height={22} preview={false} />
                )}
                {muted && (
                  <Image src={IconVolumeMuted} width={22} height={22} preview={false} />
                )}
              </div>
              <div
                style={{
                  height: 60,
                  position: 'absolute',
                  right: -6,
                  bottom: 34,
                }}
                className="volume-selector"
              >
                <Slider
                  vertical
                  defaultValue={volume}
                  min={0}
                  max={1}
                  step={0.01}
                  value={muted ? 0 : volume}
                  trackStyle={{ background: COLORS.PRIMARY }}
                  handleStyle={{ border: 0, width: 8, height: 8, marginLeft: -2 }}
                  tooltip={{ open: false }}
                  onChange={(value) => {
                    setVolume(value);
                    setMuted(value === 0);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.forwardRef(Player);
