// twitchAuth.ts


const FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID;

const TWITCH_REDIRECT_URI: string =
  process.env.REACT_APP_TWITCH_RETURN_URL || '';
const FACEBOOK_SCOPE =
  'email,public_profile';

const LOGIN_URI = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${FACEBOOK_CLIENT_ID}&redirect_uri=${TWITCH_REDIRECT_URI}&scope=${FACEBOOK_SCOPE}`;
const handleFacebookLogin = (): void => {
  window.location.href = LOGIN_URI;
};

export default handleFacebookLogin;
