import React, { useState, useContext, useMemo, useEffect } from 'react';
import {
  Image,
  Avatar,
  Typography,
} from 'antd';
import { UserContext } from '../../../../data/userContext';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RightArrow } from '../../../../assets/right-arrow.svg';
import { COLORS } from '../../../../themes/colors';

import './HeaderMenu.less';
import { getShortSubscriptionName, showGoProButton } from '../../../../utils';
import { useDetectClickOutside } from 'react-detect-click-outside';

interface IHeaderMenu {
  setShowSettingsModal?: (isOpen: boolean) => void;
  logout: () => void;
}

const HeaderMenu = ({ setShowSettingsModal, logout }: IHeaderMenu) => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const userContext = useContext(UserContext);
  const isGoogleUser = !userContext?.user?.twitch_id;
  const subscriptions = userContext?.user?.subscriptions;
  const menuRef = useDetectClickOutside({ onTriggered: () => setIsOpenMenu(false) });

  const navigate = useNavigate();

  useEffect(() => {
    if (userContext?.user) {
      if (typeof userContext?.user === 'string') {
        localStorage.removeItem('user');
        window.location.href = '/';
      }
    }
  }, [userContext?.user])


  const toggleMenu = () => {
    setIsTransitioning(true); // Start the transition animation
    setIsOpenMenu((val) => !val); // Toggle the menu state
    setTimeout(() => {
      setIsTransitioning(false); // End the transition animation after a short delay (adjust the duration as needed)
    }, 100); // Adjust the delay based on your animation duration
  };

  const styles = useMemo(() => {
    return {
      avatar: {
        background: 'var(--primary-gradient)',
        verticalAlign: 'middle',
        borderRadius: 8,
        fontFamily: 'Arial',
        fontSize: 16,
        border: '1px solid var(--border-neutral-default)',
        textTransform: 'uppercase' as const,
        color: 'var(--text-on-color)',
        fontWeight: 'bold',
        userSelect: 'none',
        display: 'flex'
      },
      menuHeader: {
        padding: 24,
        backgroundColor: 'var(--bg-neutral-default)',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '4px 4px 0 0',
        cursor: 'default'
      },
      userName: {
        fontSize: 18,
        color: 'var(--text-subdued)',
        marginLeft: 16
      },
      tierName: {
        fontSize: 18,
        color: 'var(--text-subdued)',
        display: 'flex',
        alignItems: 'center'
      },
      item: {
        fontSize: 18,
        fontWeight: 400,
        color: 'var(--text-base)',
        lineHeight: '28px'
      },
      menuContainer: {
        position: 'absolute',
        zIndex: 999,
        width: 336,
        display: 'flex',
        flexDirection: 'column',
        right: 10,
        top: 80,
        boxShadow: 'var(--shadow-md)',
        opacity: isTransitioning ? 0 : 1, // Apply opacity during the transition
        transition: 'opacity 0.1s ease', // Add a smooth transition for the opacity property
      },
    } as const;
  }, [isTransitioning]);

  const handleTutorialRun = () => {
    toggleMenu();
    localStorage.removeItem('uploadsTabTutorialWatched');
    localStorage.removeItem('editorTutorialWatched');
    localStorage.removeItem('makeClipsTutorialWatched');
    navigate('/spikes/uploads', { state: { triggerTutorial: true } });
  };
  const shortSubscriptionName = () => {
    if (!subscriptions || subscriptions.length === 0) return 'Free';
    if (subscriptions[0] && subscriptions[0]?.plan_name) {
      return getShortSubscriptionName(subscriptions[0].plan_name);
    }
  };

  const handleItemClick = (callback: any) => {
    callback();
    setIsOpenMenu(false);
  };

  return (
    <div ref={menuRef}>
      <Avatar
        onClick={toggleMenu}
        shape="square"
        size={42}
        style={styles.avatar}
        className="cursor-pointer"
      >
        {userContext?.user?.username?.charAt(0)}
      </Avatar>
      {isOpenMenu &&
        <div style={styles.menuContainer}>
          <div style={styles.menuHeader}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                shape="square"
                size={42}
                // style={{ ...styles.avatar }}
                style={shortSubscriptionName() === 'Free' ? { ...styles.avatar } : { ...styles.avatar, backgroundColor: COLORS.YELLOW }}
              >
                {userContext?.user?.username.charAt(0)}
              </Avatar>
              <Typography style={styles.userName}>{userContext?.user?.username}</Typography>
            </div>
            <Typography style={shortSubscriptionName() === 'Free' ? { ...styles.tierName } : (shortSubscriptionName() === 'Enterprise' ? { ...styles.tierName, color: 'var(--text-brand-primary)' } : { ...styles.tierName, color: 'var(--text-brand-primary)' })}>{shortSubscriptionName()}</Typography>
          </div>
          {showGoProButton(userContext?.user) &&
            <MenuItem text="Go Pro" goProButton onClick={() => handleItemClick(() => navigate('/subscriptions'))} />
          }
          {/* <MenuItem text="Dashboard" onClick={() => handleItemClick(() => navigate('/dashboard'))} /> */}

          {/* {!isGoogleUser && <MenuItem text="Live Stream AI" onClick={() => handleItemClick(() => navigate('/spikes/streams'))} />} */}
          {/* <MenuItem text="AI Clip Generator" onClick={() => handleItemClick(() => navigate('/spikes/videos'))} /> */}
          {/* <MenuItem text="Add subtitles" onClick={() => handleItemClick(() => navigate('/spikes/transcribe'))} /> */}
          {/* <MenuItem text="Loyalty Program" onClick={() => handleItemClick(() => navigate('/spikes/loyalty-program'))} /> */}
          <MenuItem text="About" onClick={() => handleItemClick(() => navigate('/about'))} />
          {setShowSettingsModal && <MenuItem text="Settings" onClick={() => handleItemClick(() => setShowSettingsModal(true))} />}
          {/* <MenuItem text="Tutorial" onClick={handleTutorialRun} /> */}
          <MenuItem text="Logout" onClick={logout} grayedOut />
        </div>
      }
    </div >
  );
};

const MenuItem = ({ onClick, text, isPro = false, grayedOut = false, goProButton = false }: { onClick: () => void; text: string; isPro?: boolean; grayedOut?: boolean; goProButton?: boolean; }) => {
  const styles = useMemo(() => {
    return {
      itemText: {
        fontSize: 18,
        fontWeight: 400,
        color: grayedOut ? 'var(--text-subdued)' : 'var(--text-base)',
        lineHeight: '28px'
      }
    } as const;
  }, []);

  return (
    <div onClick={onClick} className={`header-menu__item ${goProButton ? 'header-menu__item-go-pro primary-gradient-button' : ''}`}>
      <Typography style={styles.itemText}>{text}</Typography>
      {goProButton && <RightArrow />}
    </div>
  );
};
export default HeaderMenu;
