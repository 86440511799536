import React, { useState, useMemo } from 'react';
import './ElementsTab.less';
import TiktokElements from '../../../assets/tiktok_elements.svg';
import SocialLogosTab from './SocialLogosTab/SocialLogosTab'; // Import the SocialLogosTab component
import { Tooltip } from 'antd';
import { IStreamer } from '../../../data/intefaces/streamer.interface';

const OuttroTab: React.FC = () => <p>This is the Outtro tab content.</p>;
const ProgressBarTab: React.FC = () => (
  <p>This is the Progress Bar tab content.</p>
);

export interface IChooseSocialLogoTab {
  handleChooseLogo: (text: string, name: string, ratio: number) => void;
  user?: IStreamer;
}

const ElementsTab = ({ user, handleChooseLogo }: IChooseSocialLogoTab) => {
  const initialTabs: { name: string; component: JSX.Element }[] = [
    {
      name: 'Social Logos',
      component: <SocialLogosTab user={user} handleChooseLogo={handleChooseLogo} />,
    },
    // { name: 'Outtro', component: <OuttroTab /> },
    // { name: 'Progress Bar', component: <ProgressBarTab /> },
  ];

  const [activeTab, setActiveTab] = useState<string>(initialTabs[0].name);

  const handleTabClick = (tabName: string) => {
    // Prevent clicking "Outtro" and "Progress Bar" tabs
    if (tabName !== 'Outtro' && tabName !== 'Progress Bar') {
      setActiveTab(tabName);
    }
  };

  return (
    <div className='subtitles-wrapper scrollbar'>
      <div className='elements-tab'>
        <div className='editor-menu-tabs-list'>
          {initialTabs.map((tab) => (
            <>
              {tab.name === 'Social Logos' ?
                <div
                  key={tab.name}
                  className={`editor-menu-tab ${activeTab === tab.name && 'active'}`}
                  onClick={() => handleTabClick(tab.name)}
                >
                  {tab.name}
                </div>
                : <Tooltip title="Coming Soon" placement='top'>
                  <div
                    key={tab.name}
                    className={`editor-menu-tab ${activeTab === tab.name && 'active'}`}
                    onClick={() => handleTabClick(tab.name)}
                  >
                    {tab.name}
                  </div>
                </Tooltip>
              }
            </>

          ))}
        </div>

        {initialTabs.map((tab) => (
          <div
            key={tab.name}
            style={{ display: activeTab === tab.name ? 'block' : 'none' }}
          >
            {tab.component}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ElementsTab;
