import { Button, Layout, message, Input, Form } from 'antd';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { HeaderResponsive } from '../../components/header/header-responsive';
import { useContext, useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../themes/colors';
import { UserContext } from '../../data/userContext';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { useGoogleLogin } from '@react-oauth/google';
import { ReactComponent as IconTwitch } from '../../assets/twitch.svg';
import { ReactComponent as IconGoogle } from '../../assets/google.svg';
import handleTwitchLogin from '../../utils/twitchAuth';
import { GenericModal } from '../../components/generic-modal';
import { postAudit } from '../../services/audit.service';
import LoginImage from '../../assets/login-hero.png';

import './ResetPasswordPage.less';
import { getDifferenceInHours } from '../../utils';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [searchParams, setSearchParams] = useSearchParams();
  const refCode = searchParams.get('ref');
  const fromSearchParam = searchParams.get('from');


  const [isVerified, setIsVerified] = useState(false);
  const [resetStatus, setResetStatus] = useState(''); // New state for reset message
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');


  useEffect(() => {
    if (refCode) {
      localStorage.setItem('ref', refCode);
      searchParams.delete('ref');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (userContext?.user) {
      navigate('/spikes/videos');
    }
  }, [userContext]);

  useEffect(() => {
    if (fromSearchParam && fromSearchParam === 'api-home') {
      localStorage.setItem('login-from', fromSearchParam);
      searchParams.delete('from');
      setSearchParams(searchParams);
      if (userContext?.user) {
        navigate('/spikes/videos');
      }
    }
  }, [fromSearchParam]);

  useEffect(() => {
    // Extract the token from the URL
    const pathParts = window.location.pathname.split('/');
    const token = pathParts[pathParts.length - 1]; // Get the last part of the path

    const verifyEmailToken = async () => {
      try {
        const response = await axios.put('/auth/verify-reset-password-token', { token });
        if (response) {
          console.log(response.data); // Handle the response data here
          setIsVerified(true);
        }
      } catch (error: any) { // Use 'any' type for general error handling
        if (error.response) {
          console.error(error.response.data);
        } else {
          console.error('An unexpected error occurred:', error);
        }
      }
    };

    if (token) {
      verifyEmailToken();
    }
  }, []);


  /* 
    This is being triggered only when the Token is expired meaning
    that they won't see the google/twitch login pages if token
    is still valid (e.g. if 'streamer/profile' doesn't throw an error)
  */


  const handlePasswordReset = async () => {
    // Check if passwords match
    const pathParts = window.location.pathname.split('/');
    const token = pathParts[pathParts.length - 1]; // Get the last part of the path

    if (newPassword !== repeatPassword) {
      setResetStatus('The two passwords do not match!');
      messageApi.error('The two passwords do not match!', 3);
      return;
    }

    try {
      const response = await axios.put('/auth/set-password', {token, password:newPassword }).catch((error) => {
        if (error.response) {
          console.error(error?.response?.data);
        }
      });

      if (response?.status === 200) {
        setResetStatus('Password has been successfully reset.');
        messageApi.success('Password has been successfully reset.', 3);
        navigate('/')
      } else {
        setResetStatus('Failed to reset password. Please try again later.');
      }
    } catch (error) {
      console.error('Password reset error:', error);
      setResetStatus('Failed to reset password. Please try again later.');
    }
  };




  return (
    <div className='reset-password-page'>
      {contextHolder}
      <div className="reset-password-page___image">
        <img src={LoginImage} alt='Reset Password Image' />
      </div>
      <div className="reset-password-page___content">
        <p className="reset-password-page___subtitle">#AI Video Repurpose Tool</p>
        <p className="reset-password-page___title">Get Your Clips Now</p>
        <p className="reset-password-page___description">Try it for FREE. No credit card required</p>

        <div className="reset-password-page___form">
          <Form
            name="reset-password"
            onFinish={handlePasswordReset}
            autoComplete="off"
          >
            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: 'Please enter your new password!'
                },
                {
                  min: 8,
                  message: 'Password must be at least 8 characters!'
                }
              ]}
            >
              <Input.Password
                className='reset-password-page___input'
                placeholder="Use at least 8 letters"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="repeatPassword"
              dependencies={['newPassword']}
              rules={[
                {
                  required: true,
                  message: 'Please repeat your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password
                className='reset-password-page___input'
                placeholder="Repeat your password"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
              />
            </Form.Item>
            <p className="reset-status-message">{resetStatus}</p>

            <Form.Item>
              <Button
                id='reset-password-button'
                style={{ background: '#fff', color: '#000' }}
                className="reset-password-page___change-passoword-button"
                type="primary"
                htmlType="submit"
              >
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );



};

export default ResetPasswordPage;
