import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Input, Spin } from 'antd';
const { TextArea } = Input;
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import './BRollTab.less';
import { UserContext } from '../../../data/userContext';
import { useNavigate } from 'react-router';
import { IStreamer } from '../../../data/intefaces/streamer.interface';
import { ReactComponent as IconSearch } from '../../../assets/input-search.svg';
import { ReactComponent as IconMagicWand } from '../../../assets/magic_wand.svg';
import { AIGeneratorTextArea, ImageSearchInput } from './BRollTabInput';
import { BRollImageTab } from './BRollImageTab';
import { PricingModal } from '../../../components/pricing-modal/PricingModal';

export interface IBRollTab {
  user?: IStreamer;
  handleChooseImage: (url: string, name: string, ratio: number) => void;
  handleChooseVideo: (
    url: string,
    name: string,
    duration: number,
    ratio: number,
  ) => void;
}

interface SearchResult {
  url: string;
  height: number;
  width: number;
  thumbnail: string;
}

const BRollTab = ({ handleChooseImage, handleChooseVideo, user }: IBRollTab) => {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [activeTab, setActiveTab] = useState('Image');
  const [aiText, setAiText] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);

  // State variables to track no response for each tab
  const [noImageResponse, setNoImageResponse] = useState(false);
  const [noVideoResponse, setNoVideoResponse] = useState(false);
  const [noAIResponse, setNoAIResponse] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const userContext = useContext(UserContext);
  const navigate = useNavigate();


  useEffect(() => {

    if (firstLoad && activeTab == 'AI Generator') {
      setFirstLoad(false);
      return;
    }

    if (firstLoad) {
      // Make the initial request with "funny dog" as the query on first load
      handleImagesSearch('funny dog');
      setFirstLoad(false);
    } else {
      // Set up a debounce for subsequent searches
      const debounceTimeout = setTimeout(() => {
        handleImagesSearch(searchText || 'funny dog');
      }, 700);

      // Clear the timeout if searchText changes before the debounce completes
      return () => clearTimeout(debounceTimeout);
    }
  }, [searchText, activeTab]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (searchText) {
  //       handleImagesSearch(searchText);
  //     } else {
  //       setSearchResults([]);
  //     }
  //   }, 750);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [searchText]);

  const handleImagesSearch = async (searchInfo: string) => {
    try {
      setLoading(true);
      let apiUrl = 'image';
      let requestData;
      if (activeTab === 'Image') {
        apiUrl = '/streamer/search-pexels';
        requestData = { asset_type: 'image', query: searchInfo };
        setLoadingImage(true);
      } else if (activeTab === 'Video') {
        apiUrl = '/streamer/search-pexels';
        requestData = { asset_type: 'video', query: searchInfo };
        setLoadingVideo(true);
      } else if (activeTab === 'AI Generator') {
        apiUrl = '/streamer/generate-images';
        requestData = { user_prompt: searchInfo };
      }

      const response = await axios.post(apiUrl, requestData);
      const imageResults: SearchResult[] = response?.data;
      setSearchResults(imageResults);

      // Check if there are no results for the current tab
      if (activeTab === 'Image' && imageResults.length === 0) {
        setNoImageResponse(true);
      } else if (activeTab === 'Video' && imageResults.length === 0) {
        setNoVideoResponse(true);
      } else if (activeTab === 'AI Generator' && imageResults.length === 0) {
        setNoAIResponse(true);
      } else {
        // Reset the no response state variable when new results arrive
        if (activeTab === 'Image') {
          setNoImageResponse(false);
        } else if (activeTab === 'Video') {
          setNoVideoResponse(false);
        } else if (activeTab === 'AI Generator') {
          setNoAIResponse(false);
        }
      }
    } catch (error) {
      console.error('Error searching for images:', error);
    } finally {
      setLoading(false);
      setLoadingImage(false);
      setLoadingVideo(false);
    }
  };

  const handleAiInputChange = (value: string) => {
    setAiText(value);
  };

  const handleClick = (element: any) => {
    const ratio = element.width / element.height;
    if (activeTab === 'Video') {
      handleChooseVideo(element.url, element.name, element.duration, ratio);
    } else {
      handleChooseImage(element.url, element.name, ratio);
    }
  };

  const getAllowedAnimatedCaptions = (user: IStreamer | null | undefined): boolean => {
    if (user === null || user === undefined) {
      return false;
    }

    if (user?.permissions) {
      return user?.permissions.animated_captions[0] === 'ALL';
    }

    if (user?.subscriptions && user?.subscriptions[0]) {
      return true;
    }

    return false;

  };

  const getImageGenerationEnabledForUser = (user: IStreamer | null | undefined): boolean | undefined => {
    if (user === null || user === undefined) {
      return false;
    }

    if (user?.permissions) {
      return user?.permissions.image_generation_enabled;
    }

    if (user?.subscriptions && user?.subscriptions[0]) {
      return true;
    }

    return false;

  };

  const allowImageGeneration = getImageGenerationEnabledForUser(user); //   user?.permissions.image_generation_enabled;

  const handleTabClick = (tabText: string) => {
    if (tabText === 'AI Generator') {
      if (!allowImageGeneration) {
        handleOpenPricingModal();
        return;
      }
    }
    setFirstLoad(true);
    setActiveTab(tabText);
    setSearchText('');
    setSearchResults([]);

    // Reset no response state variables when changing tabs
    setNoImageResponse(false);
    setNoVideoResponse(false);
    setNoAIResponse(false);
  };

  const styles = useMemo(() => {
    return {
      tabsRow: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '10px 20px',
        textAlign: 'center',
      },
      searchContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      imagesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        padding: '15px',
        overflowY: 'auto',
      },
      AiContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        maxHeight: '115px',
        minHeight: '115px',
        overflowY: 'auto',
      },
      imageBox: {
        width: '95px',
        height: '95px',
        margin: '10px',
        background: '#404040',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '21px',
        cursor: 'pointer',
        animationName: 'fadeIn',
        animationDuration: '3.5s',
        animationTimingFunction: 'ease',
      },
      aiTabContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '372px',
      },
      aiSearchContainer: {
        width: '375px',
        height: '197px',
        background: '#404040',
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px 15px 25px 15px',
      },
      aiTitle: {
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        marginTop: '10px',
        resize: 'none',
      },
      aiInput: {
        width: '300px',
        height: '115px',
        borderRadius: '4px',
        background: 'rgba(121, 121, 121, 0.50) !important',
        color: '#D0C9C9',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '21.411px',
        border: 'none',
        outline: 'none',
        textAlign: 'center',
      },
      aiGenerateButton: {
        width: '375px',
        height: '44px',
        flexShrink: 0,
        borderRadius: '6px',
        background: '#2C2C2F',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        marginTop: '10px',
        borderColor: '#2C2C2F',
        cursor: 'pointer',
        transition: 'background-color 0.3s', // Add a transition for smooth hover effect
      },
      aiGenerateButtonText: {
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        textAlign: 'center',
        display: 'block',
      },
      noResponseMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        textAlign: 'center',
        marginTop: '20px',
      },
    } as const;
  }, []);

  const additionalTabs = ['Image', 'Video', 'AI Generator'];

  const proUserFeatureClass = (tabText: string) => tabText === 'AI Generator' && !allowImageGeneration ? 'starred-offset' : undefined;

  const handleOpenPricingModal = () => {
    setIsPricingModalOpened(true);
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  };

  return (
    <div className='subtitles-wrapper b-roll-tab scrollbar'>
      <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />
      <div className='editor-menu-tabs-list'>
        {additionalTabs.map((tabText, index) => (
          <div
            className={`editor-menu-tab ${tabText === activeTab && 'active'} ${proUserFeatureClass(tabText)}`}
            key={index}
            onClick={() => handleTabClick(tabText)}
          >
            {tabText}
          </div>
        ))}
      </div>
      {activeTab === 'AI Generator' ? (
        <div style={styles.aiTabContainer}>
          <div className='ai-generator-input-box'>
            <div className='ai-generator-input-title'>
              <IconMagicWand />
              <span>Generative Image</span>
            </div>
            <AIGeneratorTextArea handleAiInputChange={handleAiInputChange} />
            <div
              onClick={() => {
                setSearchText(aiText);
                setSearchResults([]);
              }}
              className="green-button-editor"
            >
              Generate Image
            </div>
          </div>
          {loading ? (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 48, color: '#FFF' }}
                    spin
                  />
                }
              />
            </div>
          ) : noAIResponse ? ( // Display "No Response" message for AI Generator tab
            <div style={styles.noResponseMessage}>
              No AI-generated images found for your prompt.
            </div>
          ) : (
            <div className='editor-grid'>
              {searchResults.map((result, index) => (
                <div
                  className='editor-grid__item transparent-background'
                  key={index}
                  onClick={() => handleClick(result)}
                >
                  <img
                    src={result.url}
                    alt={`Image ${index}`}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ) : activeTab === 'Video' || activeTab === 'Image' ? (
        <BRollImageTab
          searchText={searchText}
          activeTab={activeTab}
          loadingImage={loadingImage}
          loadingVideo={loadingVideo}
          noImageResponse={noImageResponse}
          noVideoResponse={noVideoResponse}
          searchResults={searchResults}
          setSearchText={setSearchText}
          handleClick={handleClick}
        />
      ) : null}
    </div>
  );
};

export default BRollTab;
