import React, { useContext, useEffect, useState } from 'react';
import { Calendar, Modal, Tooltip, message } from 'antd';
import moment, { Moment } from 'moment';
import useAxios from 'axios-hooks';
import 'moment/locale/en-ca';
import './SocialCalendar.less';
import { IClip, IClipEdit, IVideoClip, ISocialPost } from '../../../data/intefaces/stream.interface';
import { ClipCard } from '../../../components/upload-card/ClipCard';
import { ReactComponent as IconLinkedin } from '../../../assets/linkedin.svg';
import { ReactComponent as IconFacebook } from '../../../assets/social_facebook.svg';
import { ReactComponent as IconInstagram } from '../../../assets/instagram.svg';
import { ReactComponent as IconPinterest } from '../../../assets/pinterest.svg';
import { ReactComponent as IconTiktok } from '../../../assets/tiktok.svg';
import { ReactComponent as IconYoutubeShorts } from '../../../assets/youtube-shorts.svg';
import { PlusCircleOutlined } from '@ant-design/icons';
import { SiteSearchModal } from '../../../components/site-search-modal/SiteSearchModal';
import { UserContext } from '../../../data/userContext';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { ShareModal } from '../../../components/share-modal';
import { useSearchParams } from 'react-router-dom';
import { decompressData } from '../../../utils';

const SocialCalendarPage = () => {
  const [{ data: postsResponse }, postsRefetch] = useAxios('/streamer/social/clips');
  const [localPostsList, setLocalPostsList] = useState<IClip[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClip, setSelectedClip] = useState<IClip | null>(null);
  const [selectedSocialPost, setSelectedSocialPost] = useState<ISocialPost | null>(null);
  const [highlightedClipId, setHighlightedClipId] = useState<string | null>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [hoveredDate, setHoveredDate] = useState<string | null>(null);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [clickedDate, setClickedDate] = useState<string | null>(null);
  const [selectedClipForSharing, setSelectedClipForSharing] = useState<IClip & Partial<IClipEdit> & IVideoClip | null>(null);
  const [isLoadingShareKey, setIsLoadingShareKey] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [videoUrlForDirectShare, setVideoUrlForDirectShare] = useState<any>(null);

  const clipIdFromRedirect = searchParams.get('clipId');
  const videoUrlFromRedirect = searchParams.get('videoUrl');
  const customScheduleDateFromRedirect = searchParams.get('customScheduleDate');

  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const userHasShareKey = userContext?.user?.social_profile_key;
  const allowDirectShare = userContext?.user?.permissions?.direct_share;

  const platformIcons: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
    linkedin: IconLinkedin,
    facebook: IconFacebook,
    instagram: IconInstagram,
    pinterest: IconPinterest,
    tiktok: IconTiktok,
    youtube: IconYoutubeShorts,
  };

  useEffect(() => {
    if (postsResponse) {
      setLocalPostsList(postsResponse);
    }
  }, [postsResponse]);

  useEffect(() => {
    const clipFromRedirect = localStorage.getItem('cilpForDirectShare') ? JSON.parse(localStorage.getItem('cilpForDirectShare') as string) : null;
    if (clipFromRedirect && clipIdFromRedirect && videoUrlFromRedirect) {
      if (clipFromRedirect) {
        setSelectedClipForSharing(clipFromRedirect);
        setVideoUrlForDirectShare(videoUrlFromRedirect);

        if (customScheduleDateFromRedirect) {
          setClickedDate(customScheduleDateFromRedirect);
        }
        searchParams.delete('clipId');
        searchParams.delete('videoUrl');
        searchParams.delete('customScheduleDate');
        setSearchParams(searchParams);
        localStorage.removeItem('cilpForDirectShare');
      }
    }
  }, [clipIdFromRedirect, videoUrlFromRedirect])

  useEffect(() => {
    const timer = setTimeout(() => {
      postsRefetch();
    }, 5000);
    return () => clearTimeout(timer);
  }, [postsRefetch]);


  const handleMouseEnter = (date: string) => {
    setHoveredDate(date);
  };

  const handleMouseLeave = () => {
    setHoveredDate(null);
  };

  const handlePlusIconClick = (date: string) => {
    setClickedDate(date); // Store the clicked date
    setIsSearchModalOpen(true); // Open the modal
  };

  const getPlatformIcon = (platformName: string) => {
    const IconComponent = platformIcons[platformName.toLowerCase()];
    return IconComponent ? (
      <IconComponent className="social-media-icon" />
    ) : (
      <span style={{ fontSize: '14px', color: '#888' }}>?</span>
    );
  };

  const formatTimeFromISOString = (isoString: string) => {
    const date = new Date(isoString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${hours}:${formattedMinutes}${period}`;
  };

  const handleClipClick = (clip: IClip, socialPost: ISocialPost) => {
    setSelectedClip(clip);
    setSelectedSocialPost(socialPost);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedClip(null);
    setSelectedSocialPost(null);
  };

  const handleSuccessReschedule = (isCancelPost?: boolean) => {
    postsRefetch();
    if (isCancelPost) {
      messageApi.success('Your scheduled post has been canceled.', 5);
      handleModalClose();
    } else {
      messageApi.success('Your post has been successfully rescheduled.', 5);
    }
  };

  const getClipOrientation = (clip: IClip): 'mobile' | 'desktop' => {
    const found = clip.social_posts?.[0]?.post?.mediaUrls?.find(
      (url) => url === clip.mobile_download_url
    );
    return found ? 'mobile' : 'desktop';
  };

  const onClipSelectForSharing = (clip: IClip & Partial<IClipEdit> & IVideoClip) => {
    setSelectedClipForSharing(clip);
    handleShareClip();
  }

  const downloadUrl = selectedClipForSharing?.mobile_download_url || selectedClipForSharing?.desktop_download_url || selectedClipForSharing?.download_url

  const handleShareClip = async () => {
    if (!allowDirectShare) {
      navigate('/subscriptions');
      return null;
    }
    // Prevent clicking while loading share key
    if (isLoadingShareKey) return null;

    // Fetch social_profile_key if it doesn't exist
    if (!userHasShareKey) {
      setIsLoadingShareKey(true);
      try {
        const response = await axios.post('/streamer/social/create');
        setIsLoadingShareKey(false);

        if (response?.data && response?.data?.social_profile_key) {
          userContext?.setUser(response.data);
        } else {
          messageApi.error('Failed to get share key for your account', 5);
        }
      } catch (error) {
        setIsLoadingShareKey(false);
        messageApi.error('Failed to get share key for your account', 5);
        console.log('Share key fetch error:', error);
      }
    }
  };

  const handleCloseDirectShareModal = () => {
    setSelectedClipForSharing(null);
    setClickedDate(null);
  };

  const handleSuccessDirectShare = (isScheduled?: boolean) => {
    handleCloseDirectShareModal();
    messageApi.success(isScheduled ? 'Your post has been successfully scheduled.' : 'Your post has been successfully uploaded.', 5);
    postsRefetch();
  };

  const dateCellRender = (value: Moment) => {
    const normalizedDate = value.startOf('day');
    const dateString = normalizedDate.format('YYYY-MM-DD');

    const dateIsSameOrAfter = normalizedDate.isSameOrAfter(moment(), 'day');

    const postsForDate: { clip: IClip; post: ISocialPost }[] = localPostsList.flatMap((clip) =>
      clip.social_posts
        ?.filter((post) => moment(post.post?.scheduleDate).isSame(normalizedDate, 'day'))
        .map((post) => ({ clip, post })) || []
    );

    const hasContent = postsForDate.length > 0;

    return (
      <div
        className="calendar-cell"
        onMouseEnter={() => handleMouseEnter(dateString)}
        onMouseLeave={handleMouseLeave}
      >
        <ul className="events">
          {postsForDate.map(({ clip, post }, index) => (
            <li
              key={`${clip.id}-${post.post?.scheduleDate}-${index}`}
              className={`event-item ${clip.id === highlightedClipId ? 'highlight' : ''}`}
              onClick={() => handleClipClick(clip, post)}
            >
              {getPlatformIcon(post.post?.platforms[0] || 'unknown')}
              <span>{formatTimeFromISOString(post.post?.scheduleDate || '')}</span>
              <Tooltip overlayClassName="tooltip" title={post.post?.post} placement="top">
                <span className="truncated-text">{post.post?.post}</span>
              </Tooltip>
            </li>
          ))}
        </ul>
        {dateIsSameOrAfter &&
          <PlusCircleOutlined
            className={`plus-icon ${hasContent ? 'top-right' : 'center'}`}
            onClick={() => handlePlusIconClick(dateString)} // Pass the date
          />
        }
      </div>
    );
  };
  const isTodaySelected = moment(clickedDate).isSame(moment(), 'day');

  return (
    <div id="social-calendar" className="social-calendar-container">
      {contextHolder}
      <Calendar mode="month" dateCellRender={dateCellRender} />
      <ShareModal isOpen={Boolean(selectedClipForSharing && userHasShareKey)} handleCloseModal={handleCloseDirectShareModal} videoUrl={videoUrlForDirectShare || downloadUrl} handleSuccess={handleSuccessDirectShare} clip={selectedClipForSharing as (IClip & Partial<IClipEdit> & IVideoClip)} customScheduleDate={isTodaySelected ? null : clickedDate} />
      <Modal
        visible={isModalOpen}
        onCancel={handleModalClose}
        footer={null}
        width={550}
        centered
        closable={false}
      >
        {selectedClip && selectedSocialPost ? (
          <ClipCard
            key={selectedClip.id}
            socialPost={selectedSocialPost}
            postsRefetch={postsRefetch}
            analyticsMode
            handleSuccessReschedule={handleSuccessReschedule}
            handleDirectShareClick={() => null}
            clipNumber={1}
            streamTitle=""
            defaultOrientation={getClipOrientation(selectedClip)}
            clip={selectedClip as IClip & Partial<IClipEdit> & IVideoClip}
            platformStreamId=""
            isTranscribePage={false}
            isTwitchPage={false}
          />
        ) : (
          <p>No post selected.</p>
        )}
      </Modal>
      {isSearchModalOpen && (
        <SiteSearchModal
          socialMode
          open={isSearchModalOpen}
          onClipSelect={onClipSelectForSharing}
          onClose={() => setIsSearchModalOpen(false)}
        />
      )}
    </div>
  );
};

export default SocialCalendarPage;
