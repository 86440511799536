/* eslint-disable react/no-unknown-property */
import { useMemo } from 'react';
import Space from 'antd/lib/space';
import { Card, Typography } from 'antd';
import { COLORS } from '../../themes/colors';
import './Privacy.less';

export const PrivacyCard = () => {
  const styles = useMemo(() => {
    return {
      container: {
        borderRadius: '20px',
        height: '100%',
        backgroundColor: COLORS.HIGHLIGHT_CARD_BACKGROUND,
        opacity: 0.7,
      },
      table_title: {
        backgroundColor: 'var(--bg-brand-primary-extra-light)',
        fontWeight: 600,
        width: '100%',
        color: '#000'
      },
      containerBody: {
        padding: 0,
      },
      image: {
        filter: 'blur(8px)',
        borderRadius: 2,
      },
      overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: 'black',
      },
      login: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
      },
      privacyText: {
        color: 'var(--text-base)',
        texttransform: 'uppercase',
        fontSize: 16,
        fontWeight: 500,
        width: 1200,
        textAlign: 'start',
        lineHeight: '22px',
      },
    } as const;
  }, []);

  return (
    <Card bordered={false} bodyStyle={styles.containerBody} style={styles.container}>
      <div style={styles.overlay}></div>
      <Space className='privacy' style={styles.login} direction='horizontal' align='baseline'>
        <Typography style={styles.privacyText}>
          <p>
            In order to ensure transparency and give you more control over your Personal Information, this privacy policy (“Privacy Policy”) governs how we, PlayStream Ltd. (together, “Spikes” “we”, “our” or “us”) use,
            collect, and store Personal Data we collect or receive from or about you (“you”) such as in the following use cases:
          </p>

          <ul>
            <li>
              When you browse or visit our websites,{' '}
              <a href='https://www.spikes.studio'>https://www.spikes.studio</a>{' '}
              (“Website”)
            </li>
            <li>
              When you make use of, or interact with, our Website
              <ul>
                <li>
                  When you subscribe to our distribution list(s) /
                  newsletter(s){' '}
                </li>
                <li>
                  When you contact us (e.g. customer support, need help,
                  submit a request)
                </li>
              </ul>
            </li>
            <li>
              When you are a streamer and you make use of, or interact with,
              our desktop software application that we license (the “Desktop
              App”).
              <ul>
                <li>
                  When you download our Desktop App and you create your
                  account and when you log in
                </li>
                <li>
                  When you make use of the Desktop App, including by
                  streaming your game, receiving the challenges of the
                  challengers and collecting the payments (if any)
                </li>
                <li>When you complete a survey</li>
                <li>
                  When you contact us (e.g. customer support, need help,
                  submit a request)
                </li>
              </ul>
            </li>
            <li>
              When you are a challengers and you make use of, or interact
              with, the challenge page (the “Challenge Page”).
              <ul>
                <li>
                  When you are a challengers and you create a challenge for
                  the streamers
                </li>
              </ul>
            </li>
            <li>
              When you attend a marketing event and/or we exchange business
              cards and you provide us with your Personal Data
            </li>
            <li>
              When we use the Personal Data of our service providers (e.g.
              contact details)
            </li>
            <li>
              When you interact with us on our social media profiles (e.g.,
              Facebook, Instagram, Twitter, LinkedIn)
            </li>
          </ul>
          <p>
            We greatly respect your privacy, which is why we make every
            effort to provide a platform that would live up to the highest
            of user privacy standards. Please read this Privacy Policy
            carefully, so you can fully understand our practices and your
            rights in relation to personal data. “Personal Data” or
            “Personal Information” means any information that can be used,
            alone or together with other data, to uniquely identify any
            living human being. Please note that this is a master privacy
            policy and some of its provisions only apply to individuals in
            certain jurisdictions. For example, the legal basis in the table
            below is only relevant for GDPR-protected individuals.
          </p>
          <p>
            Important note: Nothing in this Privacy Policy is intended to
            limit in any way your statutory right, including your rights to
            a remedy or means of enforcement. Note that under some
            legislations we may be allowed to process information until you
            object to such processing (by opting out), without having to
            rely on consent or any other of the following legal bases below.
          </p>
          <h2>Table of contents: </h2>
          <ul>
            <li>
              What information we collect, why we collect it, and how it is
              used
            </li>
            <li>How we protect and retain your Personal Data</li>
            <li>How we share your Personal Data</li>
            <li>
              Additional information regarding transfers of Personal Data
            </li>
            <li>Your privacy rights</li>
            <li>Use by children</li>
            <li>Interaction with third party products</li>
            <li>Log files</li>
            <li>Analytic tools</li>
            <li>
              Specific provisions applicable under California privacy law
            </li>
            <li>Contact us</li>
          </ul>
          <p>
            this Privacy Policy can be updated from time to time and,
            therefore, we ask you to check back periodically for the latest
            version of this Privacy Policy. If we implement significant
            changes to the use of your Personal Data in a manner different
            from that stated at the time of collection, we will do our best
            efforts to notify you by posting a notice on our Website or by
            other means.
          </p>
          <ul>
            <li>
              WHAT INFORMATION WE COLLECT, WHY WE COLLECT IT, AND HOW IT IS
              USED
            </li>
          </ul>
          <table aria-label='simple table'>

            {/* <thead> */}
            <tr>
              <th align='left'>
                Specific Personal Data we collect
              </th>
              <th align='left'>
                Why is the Personal Data collected and for what
                purposes?
              </th>
              <th align='left'>
                Legal Basis (GDPR Only, If Applicable)
              </th>
              <th align='left'>
                third parties with whom we share your Personal Data
              </th>
              <th align='left'>
                Consequences of not providing the Personal Data
              </th>
            </tr>
            {/* </thead> */}
            {/* <Tbody className='privacy-table-body'> */}

            <tr>
              <td style={styles.table_title} colSpan={5}>
                When you browse or visit our Website
              </td>
            </tr>
            <tr>
              <td align='left'>
                <ul>
                  <li>Cookies, analytic tools, log files</li>
                  {/* <li>
                                                    For more information, please read our cookies policy{' '}
                    <NavLink
                      to={{
                        pathname: '/cookies',
                      }}
                    >
                                                        here
                    </NavLink>
                  </li> */}
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    Marketing, analytics, analysis, market research, to
                    improve our Website
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>Consent</li>
                  <li>Legitimate interest (e.g. essential cookies)</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  {/* <li>
                                                    Read more about the purposes of each cookie{' '}
                    <NavLink
                      style={{color: "#ff4ec6"}}
                      to={{
                        pathname: '/cookies',
                      }}
                    >
                                                        here
                    </NavLink>
                  </li> */}
                  <li>Heroku cloud services and servers</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>Cannot collect and store the information</li>
                  <li>
                    Cannot use or access some parts of the Website
                  </li>
                  {/* <li>
                                                    Read more about the purposes of each cookie{' '}
                    <NavLink
                      style={{color: "#ff4ec6"}}
                      to={{
                        pathname: '/cookies',
                      }}
                    >
                                                        here
                    </NavLink>
                  </li> */}
                </ul>
              </td>
            </tr>
            <tr>
              <td style={styles.table_title} colSpan={5}>
                When you make use of, or interact with, our Website
              </td>
            </tr>
            <tr>
              <td style={styles.table_title} colSpan={5}>
                When you subscribe to our distribution list(s) /
                newsletter(s)
              </td>
            </tr>
            <tr>
              <td align='left'>
                <ul>
                  <li>Full name</li>
                  <li>Email address</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>To send you marketing communications</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>Consent</li>
                </ul>
              </td>
              <td align='left'>
                3rd party platforms such as for the following purposes:
                <ul>
                  <li>Heroku cloud services and servers</li>
                  <li>Google Analytics (analytic tool)</li>
                  <li>Hotjar (analytic tool)</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>Cannot send you marketing communications</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={styles.table_title} colSpan={5}>
                When you contact us (e.g. customer support, need help,
                submit a request)
              </td>
            </tr>
            <tr>
              <td align='left'>
                <ul>
                  <li>Email address</li>
                  <li>Message</li>
                  <li>Location data (only latitude and longitude)</li>
                  <li>
                    Any other information that you decide to supply us
                    with
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>To process and answer questions</li>
                  <li>
                    To provide support (e.g., to solve problems, bugs or
                    issues)
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    Processing is necessary for the performance of a
                    contract to which the data subject is party or in
                    order to take steps at the request of the data
                    subject prior to entering into a contract.
                    Legitimate interest (e.g., to respond to your
                    request).
                  </li>
                  <li>
                    Legitimate interest (e.g., to respond to your
                    request).
                  </li>
                </ul>
              </td>
              <td align='left'>
                3rd party platforms such as for the following purposes:
                <ul>
                  <li>Heroku (cloud services and servers)</li>
                  <li>Google Analytics (analytic tool)</li>
                  <li>Hotjar (analytic tool)</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>Cannot process and answer question</li>
                  <li>
                    Cannot provide support (e.g., to solve problems,
                    bugs or issues)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={styles.table_title} colSpan={5}>
                When you are a streamer and you make use of, or interact
                with, our Desktop App
              </td>
            </tr>
            <tr>
              <td style={styles.table_title} colSpan={5}>
                When you download our Desktop App and you create your
                account and when you log in
              </td>
            </tr>
            <tr>
              <td align='left'>
                <ul>
                  <li>User name and password</li>
                  <li>Full name</li>
                  <li>Email address</li>
                  <li>Token to be able to download the Desktop App</li>
                  <li>PayPal Email</li>
                  <li>Profile picture</li>
                  <li>IP address</li>
                  <li>Device details</li>
                  <li>
                    Stream data (channel information, subscribers
                    information)
                  </li>
                  <li>
                    Any other data you decide to provide/supply us with
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>To be able to create an account as a streamer</li>
                  <li>
                    To allow you to log-in to the Desktop App and make
                    use of its features
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    Processing is necessary for the performance of a
                    contract to which the data subject is party or in
                    order to take steps at the request of the data
                    subject prior to entering into a contract.
                  </li>
                  <li>
                    Legitimate interest (e.g. to allow you to make use
                    of the Desktop App).
                  </li>
                </ul>
              </td>
              <td align='left'>
                3rd party platforms such as for the following purposes:
                <ul>
                  <li>Heroku (cloud services and servers)</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>Cannot create an account</li>
                  <li>
                    Cannot allow you to log-in to the Desktop App and
                    make use of its features
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={styles.table_title} colSpan={5}>
                When you make use of the Desktop App, including by
                streaming your game, receiving the challenges of the
                challengers and collecting the payments (if any)
              </td>
            </tr>
            <tr>
              <td align='left'>
                <ul>
                  <li>IP address</li>
                  <li>Device details</li>
                  <li>Location data (only latitude and longitude)</li>
                  <li>History of your challenges</li>
                  <li>
                    the list of your best challengers and challenges
                    (including, the nickname of the challengers that
                    made the challenges)
                  </li>
                  <li>
                    Any other data you decide to provide/supply us with
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>To allow you to stream (live) your game</li>
                  <li>To request streamers feedback</li>
                  <li>
                    To run and operate the services provided to you as a
                    streamer
                  </li>
                  <li>
                    To debug the Desktop App to automatically send logs
                    and analyze bugs and potential errors
                  </li>
                  <li>To improve user experience</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    Processing is necessary for the performance of a
                    contract to which the data subject is party or in
                    order to take steps at the request of the data
                    subject prior to entering into a contract.
                  </li>
                  <li>
                    Legitimate interest (e.g. to allow you to stream
                    your game).
                  </li>
                </ul>
              </td>
              <td align='left'>
                3rd party platforms such as for the following purposes:
                <ul>
                  <li>PayPal (payment system)</li>
                  <li>Heroku (cloud services and servers)</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>Cannot allow you to stream (live) your game</li>
                  <li>Cannot request streamers feedback</li>
                  <li>
                    Cannot run and operate the services provided to you
                    as a streamer
                  </li>
                  <li>Cannot improve user experience</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td align='left'>
                <ul>
                  <li>
                    Scan of your screen in specific points (the screen
                    is not identifiable and we cannot identify your
                    computer, screen or anything that you are doing in
                    your device by these pictures)
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    To detect game event (e.g., a win in a battle royal)
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    Processing is necessary for the performance of a
                    contract to which the data subject is party or in
                    order to take steps at the request of the data
                    subject prior to entering into a contract.{' '}
                  </li>
                </ul>
              </td>
              <td align='left'>
                3rd party platforms such as for the following purposes:
                <ul>
                  <li>Heroku (cloud services and servers)</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>Cannot allow you to complete a survey</li>
                  <li>Cannot process the answers of the survey</li>
                  <li>Cannot improve our services</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={styles.table_title} colSpan={5}>
                When you complete a survey
              </td>
            </tr>
            <tr>
              <td align='left'>
                <ul>
                  <li>
                    Answer to the questions of the survey (questions
                    related to the product)
                  </li>
                  <p>
                    Note: For the avoidance of doubt, we do not collect
                    any Personal Information when you complete a survey,
                    the surveys are anonymous)
                  </p>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>To allow you to complete a survey</li>
                  <li>To process the answers of the survey</li>
                  <li>To improve our services</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    Legitimate interest (e.g., to respond to your
                    request).
                  </li>
                </ul>
              </td>
              <td align='left'>
                3rd party platforms such as for the following purposes:
                <ul>
                  <li>Heroku (cloud services and servers)</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>Cannot allow you to complete a survey</li>
                  <li>Cannot process the answers of the survey</li>
                  <li>Cannot improve our services</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={styles.table_title} colSpan={5}>
                When you contact us (e.g. customer support, need help,
                submit a request)
              </td>
            </tr>
            <tr>
              <td align='left'>
                <ul>
                  <li>Streamer details</li>
                  <li>Email address</li>
                  <li>Message</li>
                  <li>
                    Any other information that you decide to
                    provide/supply us with
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>To process and answer questions</li>

                  <li>
                    To provide support (e.g., to solve problems, bugs or
                    issues)
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    Processing is necessary for the performance of a
                    contract to which the data subject is party or in
                    order to take steps at the request of the data
                    subject prior to entering into a contract.
                  </li>
                  <li>
                    Legitimate interest (e.g., to respond to your
                    request).
                  </li>
                </ul>
              </td>
              <td align='left'>
                3rd party platforms such as for the following purposes:
                <ul>
                  <li>Heroku (cloud services and servers)</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>Cannot process and answer questions</li>
                  <li>
                    Cannot provide support (e.g., to solve problems,
                    bugs or issues)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={styles.table_title} colSpan={5}>
                When you are a challengers and you make use of, or
                interact with, the Challenge Page
              </td>
            </tr>
            <tr>
              <td style={styles.table_title} colSpan={5}>
                When you are a challenger and you create a challenge for
                the streamer
              </td>
            </tr>
            <tr>
              <td align='left'>
                <ul>
                  <li>Nickname</li>
                  <li>IP address</li>
                  <li>Device details</li>
                  <li>Details of the challenge</li>
                  <li>
                    Usage pattern (e.g., click view rates from the
                    Challenge Page)
                  </li>
                  <li>
                    Payment information (we do not have access to the
                    credit card information, only the information sent
                    by PayPal about your payment, for example,
                    transaction ID)
                  </li>
                  <li>Location data (only latitude and longitude)</li>
                  <li>
                    Any other data you decide to provide/supply us with
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    To be able to create a challenge for the streamers
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    Processing is necessary for the performance of a
                    contract to which the data subject is party or in
                    order to take steps at the request of the data
                    subject prior to entering into a contract.
                  </li>
                  <li>
                    Legitimate interest (e.g. to allow you to make use
                    of the Challenge Page).
                  </li>
                </ul>
              </td>
              <td align='left'>
                3rd party platforms such as for the following purposes:
                <ul>
                  <li>PayPal (payment system)</li>
                  <li>Heroku (cloud services and servers)</li>
                  <li>Google Analytics (analytic tool)</li>
                  <li>Hotjar (analytic tool)</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    Cannot be able to create a challenge for the
                    streamers
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={styles.table_title} colSpan={5}>
                When you attend a marketing event and/or we exchange
                business cards and you provide us with your Personal
                Data
              </td>
            </tr>
            <tr>
              <td align='left'>
                <ul>
                  <li>Full Name</li>
                  <li>Email Address</li>
                  <li>Company name</li>
                  <li>Job title</li>
                  <li>Phone number</li>
                  <li>
                    Any other information that you decide to provide or
                    supply us with, to the extent that includes Personal
                    Data
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>To establish a business connection</li>
                  <li>To send you marketing communications</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    Depending on the context, consent or legitimate
                    interest (e.g., showing you certain services that
                    you have shown an interest in us)
                  </li>
                </ul>
              </td>
              <td align='left'>
                3rd party platforms such as for the following purposes:
                <ul>
                  <li>Heroku (cloud services and servers)</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>Cannot establish a business connection</li>
                  <li>
                    Cannot send you marketing communications Cannot
                    perform the applicable agreement
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={styles.table_title} colSpan={5}>
                When we use the Personal Data of our service providers
                (e.g. contact details)
              </td>
            </tr>
            <tr>
              <td align='left'>
                <ul>
                  <li>Full Name</li>
                  <li>Email Address</li>
                  <li>Company name</li>
                  <li>Payment information</li>
                  <li>
                    Any other information that you decide to provide or
                    supply us with, to the extent that includes Personal
                    Data
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>To contact our service providers</li>
                  <li>To perform the applicable agreement</li>
                  <li>To send contract-related communications</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    Processing is necessary for the performance of a
                    contract to which the data subject is party or in
                    order to take steps at the request of the data
                    subject prior to entering into a contract.
                  </li>
                  <li>
                    Compliance with a legal obligation (e.g. tax laws,
                    bookkeeping laws, etc.).
                  </li>
                  <li>
                    Legitimate interest (e.g. perform the contract, send
                    contract-related communications)
                  </li>
                </ul>
              </td>
              <td align='left'>
                3rd party platforms such as for the following purposes:
                <ul>
                  <li>Heroku (cloud services and servers)</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>Cannot communicate with you</li>
                  <li>Cannot perform the applicable agreement</li>
                  <li>Cannot send contract-related communications</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={styles.table_title} colSpan={5}>
                When you interact with us on our social media profiles
                (e.g., Facebook, Instagram, Twitter)
              </td>
            </tr>
            <tr>
              <td align='left'>
                <ul>
                  <li>Full Name</li>
                  <li>Email Address</li>
                  <li>Company name</li>
                  <li>
                    Any other Personal Data you decide to provide/supply
                    us with
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    To reply and/or respond to your request or question
                  </li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>
                    Depending on the context, consent or legitimate
                    interest (e.g. send you more information about our
                    products)
                  </li>
                </ul>
              </td>
              <td align='left'>
                3rd party platforms such as for the following purposes:
                <ul>
                  <li>Heroku (cloud services and servers)</li>
                </ul>
              </td>
              <td align='left'>
                <ul>
                  <li>Cannot reply or respond to your request</li>
                </ul>
              </td>
            </tr>
            {/* </Tbody> */}
          </table>
          <p>
            Finally, please note that some of the abovementioned Personal
            Data will be used for detecting, taking steps to prevent, and
            prosecution of fraud or other illegal activity, to identify and
            repair errors, to conduct audits, and for security purposes.
            Personal Data may also be used to comply with applicable laws,
            with investigations performed by the relevant authorities, law
            enforcement purposes, and/or to exercise or defend legal claims.
            In certain cases, we may or will anonymize or de-identify your
            Personal Data and further use it for internal and external
            purposes, including, without limitation, to improve the services
            and for research purposes. “Anonymous Information” means
            information which does not enable identification of an
            individual user, such as aggregated information about the use of
            our services. We may use Anonymous Information and/or disclose
            it to third parties without restrictions (for example, in order
            to improve our services and enhance your experience with them).
          </p>
          <h2>HOW WE PROTECT AND RETAIN YOUR INFORMATION </h2>
          <ul>
            <li>
              Security. We have implemented appropriate technical,
              organizational and security measures designed to protect your
              Personal Data. However, please note that we cannot guarantee
              that the information will not be compromised as a result of
              unauthorized penetration to our servers. As the security of
              information depends in part on the security of the computer,
              device or network you use to communicate with us and the
              security you use to protect your user IDs and passwords,
              please make sure to take appropriate measures to protect this
              information.
            </li>
            <li>
              Retention of your Personal Data. Your Personal Data will be
              stored until we proactively delete it or you send a valid
              deletion request, please note that in some circumstances we
              may store your Personal Data for longer periods of time, for
              example (i) where we are required to do so in accordance with
              legal, regulatory, tax or accounting requirements, or (ii) for
              us to have an accurate record of your dealings with us in the
              event of any complaints or challenges, or (iii) if we
              reasonably believe there is a prospect of litigation relating
              to your Personal Data or dealings. We have an internal data
              retention policy to ensure that we do not retain your Personal
              Data perpetually. Regarding retention of cookies, you can read
              more in our cookie policy{' '}
              {/* <NavLink
                style={{color: "#ff4ec6"}}
                to={{
                  pathname: '/cookies',
                }}
              >
                                        here
              </NavLink>
                                    .{' '} */}
            </li>
          </ul>
          <h2> HOW WE SHARE YOUR PERSONAL DATA</h2>
          <p>
            In addition to the recipients described above, we may share your
            Personal Data as follows:
          </p>
          <ul>
            <li>
              We may also share Personal Data with our affiliated companies.
            </li>
            <li>
              To the extent necessary, with regulators, courts or competent
              authorities, to comply with applicable laws, regulations and
              rules (including, without limitation, federal, state or local
              laws), and requests of law enforcement, regulatory and other
              governmental agencies or if required to do so by court order;
            </li>
            <li>
              If, in the future, we sell or transfer, or we consider selling
              or transferring, some or all of our business, shares or assets
              to a third party, we will disclose your Personal Data to such
              third party (whether actual or potential) in connection with
              the foregoing events;
            </li>
            <li>
              In the event that we are acquired by, or merged with, a third
              party entity, or in the event of bankruptcy or a comparable
              event, we reserve the right to transfer, disclose or assign
              your Personal Data in connection with the foregoing events,
              including, in connection with, or during negotiations of, any
              merger, sale of company assets, consolidation or
              restructuring, financing, or acquisition of all or a portion
              of our business by or to another company; and/or
            </li>
            <li>
              Where you have provided your consent to us sharing or
              transferring your Personal Data (e.g., where you provide us
              with marketing consents or opt-in to optional additional
              services or functionality).
            </li>
          </ul>
          <p>
            Note: For the avoidance of doubt, we do not sell any Personal
            Information. If you want to receive the list of the current
            recipients of your Personal Data, please make your request by
            contacting us to{' '}
            <a href='mailto: privacy@spikes.studio'>
              {' '}
              privacy@spikes.studio
            </a>
            .
          </p>
          <h2>
            ADDITIONAL INFORMATION REGARDING TRANSFERS OF PERSONAL DATA
          </h2>
          <ul>
            <li>Storage: Heroku – USA</li>
            <li>
              Access from Israel: Access from Israel is covered by the
              European Commission’s Adequacy Decision regarding Israel. You
              can read more here:
              <a href='https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en.'>
                {' '}
                https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en.
              </a>
            </li>
            <li>
              External transfers: Where we transfer your Personal Data
              outside of EU/EEA (for example to third parties who provide us
              with services), we will obtain contractual commitments from
              them to protect your Personal Data.
            </li>
          </ul>
          <h2>YOUR PRIVACY RIGHTS. HOW TO DELETE YOUR ACCOUNT</h2>
          <ul>
            <li>
              Rights: The following rights (which may be subject to certain
              exemptions or derogations) shall apply to certain individuals
              (some of which only apply to individuals protected by the
              GDPR):
              <ul>
                <li>
                  You have a right to access Personal Data held about you.
                  Your right of access may normally be exercised free of
                  charge, however we reserve the right to charge an
                  appropriate administrative fee where permitted by
                  applicable law;
                </li>
                <li>
                  You have the right to request that we rectify any Personal
                  Data we hold that is inaccurate or misleading;
                </li>
                <li>
                  You have the right to request the erasure/deletion of your
                  Personal Data (e.g. from our records). Please note that
                  there may be circumstances in which we are required to
                  retain your Personal Data, for example for the
                  establishment, exercise or defense of legal claims;
                </li>
                <li>
                  You have the right to object, to or to request
                  restriction, of the processing;
                </li>
                <li>
                  You have the right to data portability. This means that
                  you may have the right to receive your Personal Data in a
                  structured, commonly used and machine-readable format, and
                  that you have the right to transmit that data to another
                  controller;
                </li>
                <li>You have the right to object to profiling;</li>
                <li>
                  You have the right to withdraw your consent at any time.
                  Please note that there may be circumstances in which we
                  are entitled to continue processing your data, in
                  particular if the processing is required to meet our legal
                  and regulatory obligations. Also, please note that the
                  withdrawal of consent shall not affect the lawfulness of
                  processing based on consent before its withdrawal;
                </li>
                <li>
                  You also have a right to request certain details of the
                  basis on which your Personal Data is transferred outside
                  the European Economic Area, but data transfer agreements
                  and/or other details may need to be partially redacted for
                  reasons of commercial confidentiality;
                </li>
                <li>
                  You have a right to lodge a complaint with your local data
                  protection supervisory authority (i.e., your place of
                  habitual residence, place or work or place of alleged
                  infringement) at any time or before the relevant
                  institutions in your place of residence. We ask that you
                  please attempt to resolve any issues with us before you
                  contact your local supervisory authority and/or relevant
                  institution.
                </li>
              </ul>
            </li>
            <li>
              You can exercise your rights by contacting us at
              <a href='mailto: privacy@spikes.studio'>
                {' '}
                privacy@spikes.studio
              </a>
              . You may use an authorized agent to submit a request on your
              behalf if you provide the authorized agent written permission
              signed by you. To protect your privacy, we may take steps to
              verify your identity before fulfilling your request. Subject
              to legal and other permissible considerations, we will make
              every reasonable effort to honor your request promptly in
              accordance with applicable law or inform you if we require
              further information in order to fulfil your request. When
              processing your request, we may ask you for additional
              information to confirm or verify your identity and for
              security purposes, before processing and/or honoring your
              request. We reserve the right to charge a fee where permitted
              by law, for instance if your request is manifestly unfounded
              or excessive. In the event that your request would adversely
              affect the rights and freedoms of others (for example, would
              impact the duty of confidentiality we owe to others) or if we
              are legally entitled to deal with your request in a different
              way than initial requested, we will address your request to
              the maximum extent possible, all in accordance with applicable
              law.
            </li>
            <li>
              Deleting your account: Should you ever decide to delete your
              account, you may do so by emailing{' '}
              <a href='mailto: privacy@spikes.studio'>
                {' '}
                privacy@spikes.studio
              </a>
              . If you terminate your account, any association between your
              account and Personal Data we store will no longer be
              accessible through your account. However, given the nature of
              sharing on certain services, any public activity on your
              account prior to deletion will remain stored on our servers
              and will remain accessible to the public.
            </li>
          </ul>
          <h2>USE BY CHILDREN</h2>
          <p>
            We do not offer our products or services for use by children
            under the age of thirteen (13) years. We do not intend to
            collect Personal Data from anyone we know to be under thirteen
            (13) years. If you believe that we might have any such
            information, please contact us at{' '}
            <a href='mailto: privacy@spikes.studio'>
              {' '}
              privacy@spikes.studio
            </a>
            . If you are under thirteen (13), you shall not use our
            services, the Website, the Desktop App and/or the Challenge
            Page, or provide any information to the Website, the Desktop App
            and/or the Challenge Page without involvement of a parent or a
            guardian. For the purposes of the GDPR, we do not intend to
            offer information society services directly to children under
            the age of thirteen (13). Regardless, in the event that we
            become aware that you provide Personal Data in violation of
            applicable privacy laws, we reserve the right to delete it.
          </p>
          <h2>INTERACTION WITH THIRD PARTY PRODUCTS</h2>
          <p>
            We enable you to interact with third party websites, mobile
            software applications and products or services that are not
            owned or controlled by us (each a “Third Party Service”). We are
            not responsible for the privacy practices or the content of such
            Third Party Services. Please be aware that Third Party Services
            can collect Personal Data from you. Accordingly, we encourage
            you to read the terms and conditions and privacy policies of
            each Third Party Service.
          </p>
          <h2>LOG FILES</h2>
          <p>
            We use log files. We use such information to analyze trends,
            administer the Website, track users’ movement around the
            Website, and gather demographic information.
          </p>
          <h2>ANALYTIC TOOLS</h2>
          <ul>
            <li>
              Google Analytics. The Website uses a tool called “Google
              Analytics” to collect information about use of the Website.
              Google Analytics collects information such as how often users
              visit this Website, what pages they visit when they do so, and
              what other websites they used prior to coming to this Website.
              We use the information we get from Google Analytics to
              maintain and improve the Website and our products. We do not
              combine the information collected through the use of Google
              Analytics with Personal Information we collect. Google’s
              ability to use and share information collected by Google
              Analytics about your visits to this Website is restricted by
              the Google Analytics Terms of Service, available at
              <a href='https://marketingplatform.google.com/about/analytics/terms/us/'>
                {' '}
                https://marketingplatform.google.com/about/analytics/terms/us/
              </a>
              , and the Google Privacy Policy, available at
              <a href=' http://www.google.com/policies/privacy/'>
                {' '}
                http://www.google.com/policies/privacy/
              </a>
              . You may learn more about how Google collects and processes
              data specifically in connection with Google Analytics at
              <a href=' http://www.google.com/policies/privacy/partners/'>
                {' '}
                http://www.google.com/policies/privacy/partners/
              </a>
              . You may prevent your data from being used by Google
              Analytics by downloading and installing the Google Analytics
              Opt-out Browser Add-on, available at
              <a href='https://tools.google.com/dlpage/gaoptout/'>
                {' '}
                https://tools.google.com/dlpage/gaoptout/
              </a>
              .
            </li>
            <li>
              Mixpanel. We collect Personal Information such as your email
              address and your user activity through the use of Mixpanel.
              Mixpanel’s ability to use and share information is governed by
              the Mixpanel Terms of Use, available at
              <a href='https://mixpanel.com/terms/'>
                {' '}
                https://mixpanel.com/terms/
              </a>
              , and the Mixpanel Privacy Policy, available at
              <a href='https://mixpanel.com/privacy/'>
                {' '}
                https://mixpanel.com/privacy/
              </a>
              . You can opt-out of Mixpanel’s services by clicking on the
              following link:
              <a href='https://mixpanel.com/optout/'>
                {' '}
                https://mixpanel.com/optout/
              </a>{' '}
              .
            </li>
            <li>
              YouTube API. Our website and platform uses YouTube API to
              receive channel data and information. For further details
              please see the Youtube API terms of service at
              <a href='https://developers.google.com/youtube/terms/api-services-terms-of-service'>
                {' '}
                https://developers.google.com/youtube/terms/api-services-terms-of-service
              </a>{' '}
              and the Google privacy policy at
              <a href='https://policies.google.com/privacy'>
                {' '}
                https://policies.google.com/privacy
              </a>{' '}
            </li>
            <li>Twitch API usage for channel information</li>
          </ul>
          <h2>
            SPECIFIC PROVISIONS APPLICABLE UNDER CALIFORNIA PRIVACY LAW
          </h2>
          <ul>
            <li>
              California Privacy Rights: California Civil Code Section
              1798.83 permits our customers who are California residents to
              request certain information regarding our disclosure of
              Personal Information to third parties for their direct
              marketing purposes. To make such a request, please send an
              email to{' '}
              <a href='mailto: privacy@spikes.studio'>
                {' '}
                privacy@spikes.studio
              </a>
              . Please note that we are only required to respond to one
              request per customer each year.
            </li>
            <li>
              Our California Do Not Track Notice (Shine the Light): Do Not
              Track (“DNT”) is a privacy preference that users can set in
              certain web browsers. Please note that we do not respond to or
              honor DNT signals or similar mechanisms transmitted by web
              browsers, but we may allow third parties, such as companies
              that provide us with analytics tools, to collect personally
              identifiable information about an individual consumer’s online
              activities over time and across different web sites when a
              consumer uses the Services.
            </li>
            <li>
              Deletion Of Content From California Residents: If you are a
              California resident under the age of 18 and a registered user,
              California Business and Professions Code Section 22581 permits
              you to remove content or Personal Information you have
              publicly posted. To remove, please send an email to
              <a href='mailto: privacy@spikes.studio'>
                {' '}
                privacy@spikes.studio
              </a>
              . Please be aware that after removal you will not be able to
              restore removed content. In addition, such removal does not
              ensure complete or comprehensive removal of the content or
              Personal Information you have posted and that there may be
              circumstances in which the law does not require us to enable
              removal of content.
            </li>
          </ul>
          <h2>CONTACT US</h2>
          <p>
            If you have any questions, concerns or complaints regarding our
            compliance with this notice and the data protection laws, or if
            you wish to exercise your rights, we encourage you to first
            contact us at{' '}
            <a href='mailto: privacy@spikes.studio'>
              {' '}
              privacy@spikes.studio
            </a>

          </p>
        </Typography >
      </Space>
    </Card>
  );
};

export default PrivacyCard;