export enum EStreamStatus {
  FAILED = 'failed',
  LOCKED = 'locked',
  EMPTY_CHAT = 'empty chat',
  PLATFORM_UPLOAD_FAILED = 'platform_upload_failed',
  PLATFORM_UPLOAD_FAILED_UNAVAILABLE = 'platform_upload_failed_unavailable',
  PLATFORM_UPLOAD_FINISHED = 'platform_upload_finished',
  UPLOAD_LANGUAGE_BLOCKED = 'upload_langauge_blocked',
  UPLOAD_AGE_BLOCKED = 'platform_upload_failed_age_restriction',
  PLATFORM_UPLOAD_FAILED_403_ERROR = 'platform_upload_failed_403_error'
}