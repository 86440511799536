import React, { useState, useMemo, useContext } from 'react';
import './AnimatedCaptions.less';
import { UserContext } from '../../../data/userContext';
import { useNavigate } from 'react-router';
import { IStreamer } from '../../../data/intefaces/streamer.interface';
import { getEnabledAnimatedCaptionsForUser } from '../../../utils';
import { PricingModal } from '../../../components/pricing-modal/PricingModal';
import { IClip, IClipEdit } from '../../../data/intefaces/stream.interface';
import { ANIMATIONS_LIST } from '../../../constants/content-constants';
import { ReactComponent as IconStar } from '../../../assets/blurred-star.svg';

export interface IAnimatedCaptions {
  clip: IClip & Partial<IClipEdit>;
  updateFunction: (animationName: string) => void;
  user?: IStreamer;
}

const AnimatedCaptions = ({ updateFunction, clip, user }: IAnimatedCaptions) => {
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);

  const [activeBox, setActiveBox] = useState<string | null>(clip?.captions_animation_type || 'none');
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const getAnimatedCaptionsForUser = (user: IStreamer | null | undefined): string[] => {
    if (user === null || user === undefined) {
      return [];
    }

    if (!animatedCaptionsTabEnabledForUser) return [];

    if (user?.permissions) {
      return user?.permissions.animated_captions;
    }

    if (user?.subscriptions && user?.subscriptions[0]) {
      return ['wword', 'karaoke', 'bounce'];
    }

    return [];
  };

  const getAllowedAnimatedCaptions = (user: IStreamer | null | undefined): boolean => {
    if (user === null || user === undefined) {
      return false;
    }

    if (user?.permissions) {
      return user?.permissions.animated_captions[0] === 'ALL';
    }

    if (user?.subscriptions && user?.subscriptions[0]) {
      return true;
    }

    return false;

  };

  const handleOpenPricingModal = () => {
    setIsPricingModalOpened(true);
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  };

  const animatedCaptionsTabEnabledForUser = getEnabledAnimatedCaptionsForUser(user);

  const allowedCaptionsList = getAnimatedCaptionsForUser(user); // user?.permissions.animated_captions;
  const allowAllAnimations = getAllowedAnimatedCaptions(user);  //user?.permissions.animated_captions[0] === 'ALL';

  const handleClick = (animationName: string) => {
    if (animationName === 'none') {
      updateFunction('none');
      setActiveBox('none');
      return;
    }
    if (!allowAllAnimations && allowedCaptionsList?.indexOf(animationName) === -1 && (ANIMATIONS_LIST.find((item) => item.animationName === animationName)?.isActive)) {
      handleOpenPricingModal();
      return;
    }
    if (ANIMATIONS_LIST.find((item) => item.animationName === animationName)?.isActive) {
      updateFunction(animationName);
      setActiveBox(animationName); // Set the clicked box as active
    }
  };

  const isAnimationAvailableOnlyToProUser = (item: typeof ANIMATIONS_LIST[0]) => {
    return (item.isActive === true && !allowAllAnimations && allowedCaptionsList?.indexOf(item.animationName) === -1);
  }

  return (
    <div className='presets'>
      <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />

      <div className="presets__grid">
        <div onClick={() => handleClick('none')} className={`presets__item none ${activeBox === 'none' && 'active'}`}>
          <div className="presets__item-content">
            <span className='none-text'></span>
          </div>
          <div className="presets__item-footer">
            None
          </div>
        </div>
        {ANIMATIONS_LIST.map(item => (
          <div onClick={() => handleClick(item.animationName)} key={item.animationName} className={`presets__item ${activeBox === item.animationName && 'active'}`}>
            {isAnimationAvailableOnlyToProUser(item) && (
              <IconStar className='star-icon' />
            )}
            <div className="presets__item-content">
              <img
                src={item.gif}
                alt={item.displayName}
              />
            </div>
            <div className="presets__item-footer">
              {item.displayName}
            </div>

          </div>

        ))}
      </div>

    </div>
    // <div style={styles.boxContainer}>
    //   <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />

    //   <div className="editor-grid">
    //     <div
    //       className='editor-grid__item'
    //       style={{
    //         ...styles.box,
    //         ...(activeBox === 'none' ? styles.focusedBox : {}),
    //       }}
    //       onMouseEnter={() => handleHoverEnter('none')}
    //       onMouseLeave={() => handleHoverLeave('none')}
    //       onClick={() => handleClick('none')}
    //     >
    //       <div>
    //         <span style={{ height: 'auto' }}>None</span>
    //       </div>
    //     </div>
    //     {ANIMATIONS_LIST.map((box, index) => (
    //       <div
    //         key={index}
    //         className='editor-grid__item'
    //         style={{
    //           ...styles.box,
    //           ...(activeBox === box.animationName ? styles.focusedBox : {}),
    //           ...(box.isActive === false ? styles.inactiveBox : {}), // Apply inactive style if isActive is false
    //         }}
    //         onMouseEnter={() => handleHoverEnter(box.animationName)}
    //         onMouseLeave={() => handleHoverLeave(box.animationName)}
    //         onClick={() => handleClick(box.animationName)}
    //       >
    //         <div
    //           id={box.animationName} // Unique id for each element
    //         >
    //           <span style={{ height: 'auto' }}>{box.animationName}</span>
    //         </div>
    //         {box.isActive === false && (
    //           <div style={styles.comingSoonText}>Coming Soon</div>
    //         )}
    //         {box.isActive === true && !allowAllAnimations && allowedCaptionsList?.indexOf(box.animationName) === -1 && (
    //           <div style={{ ...styles.comingSoonText, color: '#ababc0' }}>Pro feature</div>
    //         )}
    //         {activeBox === box.animationName && (
    //           <div style={styles.smallText}>Animated</div>
    //         )}
    //       </div>
    //     ))}
    //   </div>
    // </div>
  );
};

export default AnimatedCaptions;
