import Modal from 'react-modal';
import './ShareProjectModal.less';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as IconLock } from '../../assets/lock_icon.svg';
import { IStream, IVideo } from '../../data/intefaces/stream.interface';

export const ShareProjectModal = ({
  open,
  setOpen,
  projectId,
  project
}: {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  projectId: string;
  project: IStream & IVideo;
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const [loading, setLoading] = useState(false);
  const [allowToEdit, setAllowToEdit] = useState(true);
  const [shareLink, setShareLink] = useState('');

  useEffect(() => {
    setAllowToEdit(project?.share_token_type === 'edit');
    setIsPublic(Boolean(project?.is_public));
  }, []);

  useEffect(() => {
    if (open) {
      generateLink(projectId);
    }
  }, [open, isPublic, allowToEdit]);

  const generateLink = async (projectId: string) => {
    setLoading(true);
    setShareLink('');
    setIsCopied(false);
    const response = await axios
      .put('/streamer/share-project', {
        id: projectId,
        is_public: isPublic,
        share_type: allowToEdit ? 'edit' : 'view',
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });

    if (response?.data) {
      setShareLink(response?.data);
    }

    setLoading(false);
  };

  const onClose = () => {
    setOpen(false);
    setIsCopied(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    setIsCopied(true);
  };

  return (
    <Modal
      isOpen={open}
      className="Modal"
      overlayClassName="Overlay"
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
    >
      <div className="share-project-modal">
        {loading && (
          <div className="share-project-modal__loader">
            <Spin
              indicator={
                <LoadingOutlined style={{ fontSize: 36, color: 'var(--text-base)' }} spin />
              }
            />
          </div>
        )}
        <span onClick={onClose} className="icon-close">
          X
        </span>
        <div className="title">Public link created</div>
        <div className="text-subtitle">
          Please note that this link grants open access to view, download and
          edit the videos. To remove access, simply turn off the switch here to
          make it private.
        </div>
        <div className='share-project-modal__toggles'>
          <div className="share-project-modal__switch-container">
            <div className="face-switch no-face">
              <div className="check">
                <input
                  disabled={loading}
                  checked={isPublic}
                  onChange={() => setIsPublic((prev) => !prev)}
                  id="share-public-switch"
                  type="checkbox"
                />
                <label htmlFor="share-public-switch"></label>
              </div>
            </div>
            <div className="description">Make this project public</div>
          </div>
          <div className="share-project-modal__switch-container">
            <div className="face-switch no-face">
              <div className="check">
                <input
                  disabled={loading}
                  checked={allowToEdit}
                  onChange={() => setAllowToEdit((prev) => !prev)}
                  id="allow-edit-switch"
                  type="checkbox"
                />
                <label htmlFor="allow-edit-switch"></label>
              </div>
            </div>
            <div className="description">Allow to edit clips</div>
          </div>
        </div>

        {!isPublic &&
          <div className='share-project-modal__private'>
            <div>Your project is private now</div>
            <IconLock />
          </div>
        }

        {shareLink && isPublic && (
          <div className="share-project-modal__input">
            <div className="share-project-modal__input-link">
              {shareLink}
            </div>
            <div
              onClick={handleCopyLink}
              className="share-project-modal__input-button"
            >
              {isCopied ? 'Copied!' : 'Copy Link'}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ShareProjectModal;
