import { useMemo, useState } from 'react';
import { Image, Typography } from 'antd';
import { COLORS } from '../../themes/colors';
import IconLightning from '../../assets/lightning-icon.svg';
import { ErrorItem } from '../stream-card/StreamCard';


export const EmptyStreamCard = () => {
  const styles = useMemo(() => {
    return {
      streamBarContent: {
        flex: '1',
        display: 'flex',
        padding: '11px 24px',
        justifyContent: 'space-between',
      },
      streamBarDetailsContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      videoName: {
        fontSize: '20px',
        fontWeight: 400,
        color: COLORS.WHITE,
      },
      lightningButton: {
        borderLeft: `1px solid ${COLORS.BACKGROUND_DARK_GRAY}`,
        width: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      videosBlock: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
    } as const;
  }, []);

  return (
    <div
      className="stream-card"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore  */}
      <div style={styles.videosBlock}>
        <div
          style={{
            borderRadius: '8px 8px 0 0',
            backgroundColor: 'var(--bg-brand-secondary)',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div style={styles.streamBarContent}>
            <div style={styles.streamBarDetailsContainer}>
              <div style={{ maxWidth: 220 }}>
                <Typography className="video-name" style={styles.videoName}>
                  No Streams Found
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            </div>
          </div>
          <div style={{ ...styles.lightningButton }}>
            <Image src={IconLightning} preview={false} />
          </div>
        </div>
        <ErrorItem isNoStreams isEmptyChat={false} />
      </div>
    </div>
  );
};

export default EmptyStreamCard;