import styled from 'styled-components';
import { Avatar, Layout as AntLayout, Typography } from 'antd';
import { rem } from 'polished';
import { COLORS } from '../themes/colors';
// import { Footer as AppFooter } from '../components/footer';
import FooterResponsive from '../components/footer/FooterResponsive';
import HeaderResponsive from '../components/header/header-responsive/HeaderResponsive';
import { Sidebar } from '../components/sidebar';
import { LightHeader } from '../components/light-header';
import { useWindowDimensions } from '../pages/Editor/Editor.page';
import { BigSidebar } from '../components/big-sidebar';

// import BG from '../assets/inner-bg.png';

// const { Text } = Typography;

const {
  Sider: AntAside,
  Header: AntHeader,
  Footer: AntFooter,
  Content: AntContent,
} = AntLayout;

const StyledFrontLayoutContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const StyledMainLayout = styled(AntLayout)`
  height: 100%;
  background: transparent;
  margin-left: auto;
  margin-right: auto;
  .ant-layout {
    background: transparent;
  }
`;

const Content = styled(AntContent)`
  background-color:'transparent';
  padding: ${rem('80px')} 0;
  min-height: unset !important;
  /* margin-top: 150px; */

  @media (max-width: 768px) {
    margin-top: 0 !important; /* Override margin-top for screens smaller than 768px */
    padding-top: 80px !important;
  }
`;

type TMainLayout = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  marginTop?: string;
  withoutSidebar?: boolean;
};

const MainLayout = ({ header, footer, children, marginTop, withoutSidebar = false }: TMainLayout) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 1120;
  return (
    <StyledFrontLayoutContainer className="main-layout-container scrollbar">
      <StyledMainLayout>
        <AntLayout>
          <LightHeader withoutSidebar={withoutSidebar} />
          {/* {!isMobile && <Sidebar />} */}
          {/* <HeaderResponsive></HeaderResponsive> */}
          {!isMobile && !withoutSidebar && !location.pathname.includes('/subscriptions') && <BigSidebar />}
          {/* <Content style={{ marginTop: marginTop || 70, paddingLeft: isMobile ? 0 : 80 }}>{children}</Content> */}
          <Content style={{ marginTop: marginTop || 70, paddingLeft: isMobile || withoutSidebar || location.pathname.includes('/subscriptions') ? 0 : 220, background: 'transparent' }}>{children}</Content>
          {/* <FooterResponsive></FooterResponsive> */}
        </AntLayout>
      </StyledMainLayout>
    </StyledFrontLayoutContainer>
  );
};

export default MainLayout;
